export enum EventTag {
    Webinar = 'webinar',
    Recording = 'video recording',
}

export enum VideoSource {
    Vimeo = 'vimeo',
    Youtube = 'youtube',
}

export enum BootstrapBreakpoint {
    ExtraSmall,
    Small,
    Medium,
    Large,
    ExtraLarge,
    ExtraExtraLarge,
}

export enum LawsAndRegsDataSourceType {
    Micor = 'Micor',
    ITC = 'ITC',
    MV = 'MV',
}

export enum MvDocumentCategoryKey {
    OverviewDocuments = 'O',
    GeneralRequirements = 'GO',
    SpecificRequirements = 'S',
    LinkedRequirements = 'G',
}

export enum OnLoadPersonalisationType {
    None,
    Querystring,
    LocalStorage,
    DefaultLocalStorage,
}

export enum MarketSearchToolSearchType {
    StrongMarkets,
    Markets,
}
