import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'reactstrap';
import './NavigationMenu.scss';
import NavigationMenuItem from './NavigationMenuItem';
import { MenuItemData } from './MenuItemData';
import { useAppSelector } from '../../hooks/reduxHooks';
import { BootstrapBreakpoint } from '../../models/enums';
import { PageService } from '../../services/PageService';

interface NavigationProps {
    data: {
        menu: Array<MenuItemData>;
        isShowMobileMenu: boolean;
    };
}

const NavigationMenu = (props: NavigationProps) => {
    const [isMobile, setIsMobile] = useState(false);
    const [activeMainMenuId, setActiveMainMenuId] = useState<string | null>(null);
    const [focusMainMenuId, setFocusMainMenuId] = useState<string | null>(null);

    const navBarRef = useRef<HTMLDivElement>(null);

    const applicableBootstrapBreakpoints = useAppSelector<BootstrapBreakpoint[]>((state) => state.page.applicableBootstrapBreakpoints);
    const pageService = useAppSelector<PageService>((state) => state.singletonServices.pageService);

    useEffect(() => {
        const isMobile =
            pageService.IsCurrentBootstrapBreakpointOrGreater(applicableBootstrapBreakpoints, BootstrapBreakpoint.ExtraSmall) &&
            !pageService.IsCurrentBootstrapBreakpointOrGreater(applicableBootstrapBreakpoints, BootstrapBreakpoint.Medium);

        setIsMobile(isMobile);
    }, [applicableBootstrapBreakpoints]);

    const handleClickOutside = (event: UIEvent | MouseEvent) => {
        if (navBarRef && !navBarRef.current?.contains(event.target as Node)) {
            setActiveMainMenuId(null);
        }
    };

    const handleMenuItemOpenClose = (menuItem: MenuItemData, isOpen: boolean) => {
        if (menuItem.level === 1) {
            if (isOpen) {
                if (activeMainMenuId !== menuItem.id) {
                    // new main menu opened, set focus and active to the new main menu
                    setFocusMainMenuId(menuItem.id);
                    setActiveMainMenuId(menuItem.id);
                }
            } else {
                if (activeMainMenuId === menuItem.id) {
                    // current active main menu closed, keep focus to the current main menu, but set active as null
                    setFocusMainMenuId(activeMainMenuId);
                    setActiveMainMenuId(null);
                }
            }
        } else if (menuItem.level !== 1) {
            const mainMenuItemId = getMainMenuId(menuItem.id);
            setFocusMainMenuId(mainMenuItemId);
            setActiveMainMenuId(null);

            setTimeout(() => (document.querySelector(`[data-menuitemid='${mainMenuItemId}']`) as HTMLLIElement).focus(), 10);
        }
    };

    const getMainMenuId = (menuItemId?: string) => {
        if (!menuItemId) return null;

        const idSplit = menuItemId.split('_');
        return idSplit.length > 0 ? idSplit[0] : null;
    };

    const handleMenuItemBlur = (menuItem: MenuItemData, relatedMenuItemId: string | null, isKeyboardEvent: boolean) => {
        if (isKeyboardEvent) {
            const currentMainMenuId = getMainMenuId(menuItem.id);
            const newMainMenuId = getMainMenuId(relatedMenuItemId);

            if (currentMainMenuId !== newMainMenuId) {
                // when user moved to new main menu using keyboard,
                // set focus to the new main menu and close the previously active main menu
                setFocusMainMenuId(newMainMenuId);
                setActiveMainMenuId(null);
            }
        }
    };

    useEffect(() => {
        if (window) {
            window.addEventListener('mousedown', handleClickOutside, false);
        }

        // returned function will be called on component unmount
        return () => {
            if (window) {
                window.removeEventListener('mousedown', handleClickOutside);
            }
        };
    }, [handleClickOutside]);

    return (
        <div ref={navBarRef} data-testid="navigation-bar">
            <div id="mainnavigation" className="mainnavigation d-print-none">
                <Container>
                    <nav className={`navbar navbar-expand-md ${!isMobile || props.data.isShowMobileMenu ? 'open' : ''}`}>
                        <div className="nav-wrapper collapse navbar-collapse">
                            <ul className="nav">
                                {props.data?.menu?.length > 0 &&
                                    props.data?.menu
                                        .filter((menuItem) => menuItem.showInGlobalNav)
                                        .map((menuItem: MenuItemData) => {
                                            return (
                                                <NavigationMenuItem
                                                    key={`menuitem_${menuItem.id}`}
                                                    data={{
                                                        menuItem: menuItem,
                                                        mainMenuId: menuItem.id,
                                                        activeMainMenuId: activeMainMenuId,
                                                        focusMainMenuId: focusMainMenuId,
                                                        isMobile: isMobile,
                                                    }}
                                                    onMenuItemOpenClose={handleMenuItemOpenClose}
                                                    onMenuItemBlur={handleMenuItemBlur}
                                                />
                                            );
                                        })}
                            </ul>
                        </div>
                    </nav>
                </Container>
            </div>
        </div>
    );
};

export default NavigationMenu;
