import { IContentItem } from '@kontent-ai/delivery-sdk';
import { client } from '../config';
import { VideoData } from '../models/VideoData';

export class VideoService {
    public getVideoData = (systemCodename: string): Promise<VideoData> =>
        new Promise<VideoData>((resolve, reject) => {
            client
                .items()
                .equalsFilter('system.type', 'video')
                .equalsFilter('system.codename', systemCodename)
                .depthParameter(0)
                .toPromise()
                .then((response) =>
                    resolve({
                        videoId: response.data.items[0].elements.video_id.value,
                        thumbnailUrl: response.data.items[0].elements.video_thumbnail?.value[0]?.url,
                        videoTranscript: response.data.items[0].elements.video_transcript?.value,
                    }),
                )
                .catch((err) => reject(err));
        });

    public getVideoAuth = (): Promise<IContentItem> =>
        new Promise<IContentItem>((resolve, reject) => {
            client
                .items()
                .equalsFilter('system.type', 'authentication_call_to_action')
                .equalsFilter('system.codename', 'video_auth_call_to_action')
                .depthParameter(0)
                .toPromise()
                .then((response) => resolve(response.data.items[0]))
                .catch((err) => reject(err));
        });
}

export default VideoService;
