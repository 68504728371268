import React, { BaseSyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { omit } from 'lodash';
import { IContentItem, IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import { ComButton, ComFieldInput, ComFieldSelect } from '@exporter-services/common-ui';
import LoadingOverlay from 'react-loading-overlay-ts';
import SyncLoader from 'react-spinners/SyncLoader';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { useGetDisplayType } from '../../hooks/useGetDisplayType';
import { KenticoUserTypes, UserType } from '../../models/ReduxModels';
import { getKenticoUserTypes } from '../../providers/reducers/taxonomySlice';
import ComponentResolver from '../../ComponentResolver';
import { scrollTo } from '../../utils/scrollUtils';
import { AbnField } from '../abn/AbnField';
import { useCreateProfileMutation } from '../../providers/reducers/userProfileApi';
import { PageName } from '../../models/PageName';
import { setUserProfile } from '../../utils/authUtils';
import { sendUserEvent } from '../../utils/tagManagerUtils';

interface UserProfileProps {
    data: {
        item: IContentItem;
        linkedItems: IContentItemsContainer;
    };
    match: any;
}

export const UserProfile = (props: UserProfileProps) => {
    const formRef = useRef<HTMLFormElement>(null);
    const { isMobile, isTablet } = useGetDisplayType();
    const dispatch = useAppDispatch();
    const userTypeTaxonomy = useAppSelector<KenticoUserTypes>((state) => state.taxonomy.userTypes);
    const navigate = useNavigate();
    const location = useLocation();
    const [createProfile] = useCreateProfileMutation();

    const [selectedUserType, setUserType] = useState('');
    const [isFetchingAbnDetails, setIsFetchingAbnDetails] = useState(false);

    const formContext = useForm({ mode: 'onBlur', reValidateMode: 'onBlur' });
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        setValue,
        getValues,
        reset,
    } = formContext;

    const displayAdditionalFields = useMemo(() => selectedUserType.endsWith('_business'), [selectedUserType]);

    const fromUrl = location?.state?.from;

    const handleUserTypeFocus = () => {
        if (isMobile || isTablet) scrollTo(formRef);
    };

    const saveUserProfile = async (data: FieldValues) => {
        if (loading) return;

        const apiPayload = !data.userType.endsWith('_business')
            ? omit(data, ['abn', 'role'])
            : { ...data, role: data.role.trim(), abn: data.abn.replaceAll(' ', '') };

        const response = await createProfile({
            Abn: apiPayload.abn,
            BusinessType: apiPayload.userType,
            Role: apiPayload.role,
        });

        if (!response.error) {
            setUserProfile(response.data);
            sendUserEvent('account_created', response.data);

            navigate(fromUrl && !fromUrl.includes(PageName.SignIn) ? fromUrl : `/${PageName.MyAccount}`, { replace: true });
        }
    };

    useEffect(() => {
        dispatch(getKenticoUserTypes());
    }, []);

    const loading = isFetchingAbnDetails || isSubmitting;

    const loadingText = useMemo(() => {
        if (isSubmitting) return 'Saving your profile. Please wait...';
        if (isFetchingAbnDetails) return 'Fetching ABN details. Please wait...';
        return '';
    }, [isFetchingAbnDetails, isSubmitting]);

    return (
        <LoadingOverlay active={loading} spinner={<SyncLoader />} text={loadingText} className="loader">
            <FormProvider {...formContext}>
                <form className={`mb-5${loading ? ' overflow-hidden' : ''}`} onSubmit={handleSubmit((data) => saveUserProfile(data))} ref={formRef}>
                    <ComFieldSelect
                        controlId="userType"
                        label="Choose the option that best describes you"
                        errorMessage={errors?.userType?.message as string}
                        required
                        options={userTypeTaxonomy?.data?.map((userType: UserType) => ({ key: userType.codename, value: userType.name }))}
                        displayEmptyOption
                        emptyOptionText="Select the best option..."
                        onFocus={handleUserTypeFocus}
                        selectedValue={getValues('userType')}
                        {...register('userType', {
                            required: 'Please select an option.',
                            onChange: (event: BaseSyntheticEvent) => {
                                setUserType(event.target?.value);
                                setValue('userType', event.target?.value, { shouldValidate: true });
                            },
                        })}
                    />
                    {displayAdditionalFields && (
                        <>
                            <AbnField setIsFetchingAbnDetailsFlag={setIsFetchingAbnDetails} required={fromUrl?.includes(PageName.GoGlobalWebinars)} />
                            <ComFieldInput
                                controlId="role"
                                label="Your role in the organisation"
                                hintText="Example, Export Manager or CEO."
                                errorMessage={errors?.role?.message as string}
                                required
                                {...register('role', {
                                    maxLength: { value: 100, message: "Check your role doesn't have more than 100 characters." },
                                    validate: {
                                        required: (value) => {
                                            if (value.trim().length < 2) return 'Enter a minimum of 2 characters.';
                                        },
                                    },
                                })}
                            />
                        </>
                    )}
                    {props.data.item?.elements?.linked_items?.['linkedItems']?.map((item: IContentItem) => (
                        <ComponentResolver data={{ item: item, linkedItems: props.data.linkedItems }} key={item.system.id} match={props.match} />
                    ))}
                    <div className="button-container">
                        <ComButton
                            variant="link"
                            onClick={() => {
                                reset({ userType: '', abn: '', role: '' });
                                setUserType('');
                            }}
                            disabled={isSubmitting}
                        >
                            Clear form
                        </ComButton>
                        <ComButton type="submit" disabled={isSubmitting}>
                            Save
                        </ComButton>
                    </div>
                </form>
            </FormProvider>
        </LoadingOverlay>
    );
};
