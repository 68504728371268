import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { PageName } from '../../models/PageName';
import { useLazyGetProfileQuery } from '../../providers/reducers/userProfileApi';
import LoadingOverlay from 'react-loading-overlay-ts';
import SyncLoader from 'react-spinners/SyncLoader';
import { setUserProfile } from '../../utils/authUtils';
import { sendUserEvent } from '../../utils/tagManagerUtils';

export const Callback = () => {
    const [query] = useSearchParams();
    const navigate = useNavigate();
    const { isAuthenticated, isLoading } = useAuth0();
    const [getProfile] = useLazyGetProfileQuery();

    useEffect(() => {
        if (!isLoading && isAuthenticated) (async () => await performPostSignInActions())();
    }, [query, isLoading, isAuthenticated]);

    const performPostSignInActions = async () => {
        document.cookie = 'ggt.is.authenticated=true; expires=0; path=/';

        const redirectUrl = query.get('url');
        const result = await getProfile({});

        if (result.isSuccess && result.data) {
            setUserProfile(result.data);
            sendUserEvent('signed_in', result.data);

            if (redirectUrl?.includes(PageName.SignIn)) navigate(`/${PageName.MyAccount}`, { replace: true });
            else if (redirectUrl) navigate(redirectUrl, { replace: true });
        } else {
            navigate(`/${PageName.SignIn}/${PageName.CompleteYourProfile}`, { replace: true, state: { from: redirectUrl } });
        }
    };

    return <LoadingOverlay active={true} spinner={<SyncLoader />} text="Fetching your profile. Please wait..." className="loader mt-8" />;
};
