import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import BackArrow from '../../assets/backArrow.svg';
import AccountMenu from '../../controls/menus/AccountMenu';
import { MenuItemData } from '../../controls/menus/MenuItemData';

interface FeedbackBannerProps {
    data: {
        menu: Array<MenuItemData>;
    };
}

const FeedbackBanner = (props: FeedbackBannerProps) => {
    return (
        <div data-testid="feedback">
            <Container fluid className="feedback-banner d-print-none">
                <Container>
                    <Row>
                        <Col xs={7} className="businessgovau-holder">
                            <a aria-label="business.gov.au" href="https://www.business.gov.au/">
                                <img src={BackArrow} alt="Back arrow" />
                                <strong>business</strong>.gov.au
                            </a>
                        </Col>
                        <Col xs={5} className="account-menu-container">
                            <AccountMenu data={{ menu: props.data?.menu }} />
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    );
};

export default FeedbackBanner;
