import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import { ContentItem } from '@exporter-services/common-ui';
import ComponentResolver from '../../ComponentResolver';
import { useAppSelector } from '../../hooks/reduxHooks';
import { UserGeolocationInfo } from '../../models/ReduxModels';
import { useGetMvImportDocumentsListQuery } from '../../providers/reducers/mvLawsAndRegsApi';
import { MvDocumentCategoryKey } from '../../models/enums';
import { useGetMicorLinksForCommoditiesQuery } from '../../providers/reducers/commoditiesApi';

interface FilteredLawsAndRegsRulesAndRestrictionsMarketResultsProps {
    match?: any;
    onLoad?: (dataAvailable: boolean, title: string) => void;
    onLoadDescription?: (dataAvailable: boolean, title: string) => void;
    data: {
        item: ContentItem;
        linkedItems: IContentItemsContainer;
    };
}

const FilteredLawsAndRegsRulesAndRestrictionsMarketResults = (props: FilteredLawsAndRegsRulesAndRestrictionsMarketResultsProps) => {
    const [filteredNoRulesAndRequirementsItems, setFilteredNoRulesAndRequirementsItems] = useState<Array<string>>([]);
    const [noRulesAndRequirementsFiltered, setNoRulesAndRequirementsFiltered] = useState<boolean>(false);

    const geolocationInfo = useAppSelector<UserGeolocationInfo>((state) => state.user.geolocationInfo);
    const [itemsToRender, setItemsToRender] = useState([]);
    const codeNames = useRef<Array<string>>([]);
    const { data, isSuccess, isFetching } = useGetMvImportDocumentsListQuery(
        {
            sector: props.match.params.sector,
            market: props.match.params.market,
            type: MvDocumentCategoryKey.SpecificRequirements,
        },
        { skip: !props.match.params.sector || !props.match.params.market },
    );

    const {
        data: micorData,
        isSuccess: micorDataSuccess,
        isFetching: micorDataFetching,
    } = useGetMicorLinksForCommoditiesQuery({
        sector: props.match.params.sector,
        market: props.match.params.market,
    });

    const getParent = (codeName: string): string => {
        for (const [key, value] of Object.entries(props.data.linkedItems)) {
            if (value?.elements?.description && value?.elements?.description['linkedItemCodenames']?.includes(codeName)) {
                if (codeNames.current.includes(key)) {
                    return key;
                } else {
                    return getParent(key);
                }
            }
        }
        return '';
    };

    const getContainerParentName = useCallback(
        (codeName: String): Array<string> => {
            let codeNames = Array<string>();

            for (const key in props.data.linkedItems) {
                if (
                    props.data.linkedItems[key]?.elements.laws_and_regulations_resource_type?.value.length > 0 &&
                    props.data.linkedItems[key]?.elements.laws_and_regulations_resource_type?.value[0].codename === codeName
                ) {
                    codeNames.push(props.data.linkedItems[key].system.codename);
                }
            }
            let parentCodeNames = Array<string>();
            for (const val of codeNames) {
                const parentName = getParent(val);
                parentCodeNames.push(parentName);
            }
            return parentCodeNames;
        },
        [getParent],
    );

    useEffect(() => {
        codeNames.current = props.data.item.elements.linked_items['linkedItems'].map((m) => m.system.codename);
    });

    useEffect(() => {
        if (noRulesAndRequirementsFiltered) {
            const data = props.data.item.elements.linked_items['linkedItems'].filter((m) => {
                return !filteredNoRulesAndRequirementsItems.includes(m.system.codename);
            });
            setItemsToRender(data);
        }
    }, [noRulesAndRequirementsFiltered, filteredNoRulesAndRequirementsItems, props.data.item.elements.linked_items]);

    useEffect(() => {
        const getRulesAndRestrictionsSpecificRequirementsDocumentListContainerParentNames = (): Array<string> => {
            return getContainerParentName('export_requirements_specific_requirements_document_list');
        };

        const getRulesAndRestrictionsDocumentsOffshoreContainerParentNames = (): Array<string> => {
            return getContainerParentName('export_requirements_documents_offshore');
        };
        const getMicorProhibitedExportRequirementsContainerParentNames = (): Array<string> => {
            return getContainerParentName('micor_prohibited_export_requirements');
        };
        const getMicorSpecifieExportRequirementsContainerParentNames = (): Array<string> => {
            return getContainerParentName('micor_specific_export_requirements');
        };

        if (!geolocationInfo.retrieved || isFetching || micorDataFetching) {
            return;
        }

        let codeNames: string[] = [];
        if (geolocationInfo.data.isOffshore) {
            codeNames = getRulesAndRestrictionsSpecificRequirementsDocumentListContainerParentNames();
        } else {
            codeNames = getRulesAndRestrictionsDocumentsOffshoreContainerParentNames();
        }

        if (!geolocationInfo.data.isOffshore && data?.Categories?.length === 0) {
            codeNames = codeNames.concat(getRulesAndRestrictionsSpecificRequirementsDocumentListContainerParentNames());
        }
        let micorDataCodeNames: string[] = [];
        if (!micorDataFetching && micorData) {
            if (micorData.ProhibitedCommodities.length === 0) {
                micorDataCodeNames = micorDataCodeNames.concat(getMicorProhibitedExportRequirementsContainerParentNames());
            }
            if (micorData.NonProhibitedCommodities.length === 0) {
                micorDataCodeNames = micorDataCodeNames.concat(getMicorSpecifieExportRequirementsContainerParentNames());
            }
            codeNames = codeNames.concat(micorDataCodeNames);
        }

        setFilteredNoRulesAndRequirementsItems(codeNames);
        setNoRulesAndRequirementsFiltered(true);
    }, [geolocationInfo.retrieved, isSuccess, isFetching, micorDataSuccess, micorDataFetching]);

    return (
        <Row className="filtered-results">
            <Col>
                {itemsToRender.map((a: ContentItem) => {
                    return (
                        <ComponentResolver
                            data={{ item: a, linkedItems: props.data.linkedItems }}
                            key={a.system.id}
                            match={props.match}
                            onLoad={props.onLoad}
                            type={props.data.item.elements.market_multiple_choice?.value?.[0]?.codename}
                        />
                    );
                })}
            </Col>
        </Row>
    );
};

export default FilteredLawsAndRegsRulesAndRestrictionsMarketResults;
