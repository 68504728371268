import { useContext, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { personalisationContext } from '../providers/personalisation';
import useGetUrlIndustryAndSector, { GetUrlIndustryAndSector } from './useGetUrlIndustryAndSector';
import useValidateOnLoadStoredPersonalisation, { ValidateOnLoadStoredPersonalisation } from './useValidateOnLoadStoredPersonalisation';
import useValidateOnLoadQuerystring, { ValidateOnLoadQuerystring } from './useValidateOnLoadQuerystring';
import { IndustryOption, SectorOption } from '../models/IndustrySector';
import { generatePersonalisationClearedQueryString, generateServiceQueryString, parseIndustrySectorParam } from '../utils/personalisationUtils';
import { setIndustrySector } from '../providers/personalisation/reducer';
import { CommonConstants } from '../CommonConstants';
import { PersonalisationStateInterface } from '../providers/personalisation/types';
import { useAppDispatch } from './reduxHooks';
import { setOnLoadPersonalisation } from '../providers/reducers/productServiceSelectionSlice';
import { OnLoadPersonalisationType } from '../models/enums';

type VerifyValidation = { isVerified: boolean; isValidIndustry?: boolean; industry?: string; sector?: string };

export default function usePersonalisedPageOnLoadPersonalisation(
    industryOptionList: IndustryOption[],
    industryOptionListRetrieved: boolean,
): { processed: boolean; validIndustry: boolean; industryOption: IndustryOption; sectorOption: SectorOption } {
    const { personalisationState, dispatch } = useContext(personalisationContext);
    const dispatchRedux = useAppDispatch();
    const getUrlIndustryAndSector = useGetUrlIndustryAndSector();
    const validateOnloadStoredPersonalization = useValidateOnLoadStoredPersonalisation(industryOptionList, industryOptionListRetrieved);
    const validateOnloadQuerystring = useValidateOnLoadQuerystring(industryOptionList, industryOptionListRetrieved);
    const [industryOption, setIndustryOption] = useState<IndustryOption>();
    const [sectorOption, setSectorOption] = useState<SectorOption>();
    const [validIndustry, setValidIndustry] = useState<boolean>(false);
    const [processed, setProcessed] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!readyToVerifyValidation(getUrlIndustryAndSector, validateOnloadQuerystring, validateOnloadStoredPersonalization)) {
            return;
        }

        const originalUrl = window.location.href;

        const querystringVerified = verifyValidationForQuerystring(validateOnloadQuerystring, getUrlIndustryAndSector);
        let validationComplete = false;
        if (!querystringVerified.isVerified) {
            return;
        }

        let industry: string, sector: string;
        let isViaQuerystring = false;
        let isDefaultStoredPersonalisation = false;
        if (validateOnloadQuerystring.isValid) {
            industry = querystringVerified.industry;
            sector = querystringVerified.sector;
            validationComplete = isViaQuerystring = true;
            setValidIndustry(querystringVerified.isValidIndustry);
        } else {
            const storedPersonalisationVerified = verifyValidationForStoredPersonalisation(
                validateOnloadStoredPersonalization,
                personalisationState,
                navigate,
            );
            if (!storedPersonalisationVerified.isVerified) {
                return;
            }
            if (validateOnloadStoredPersonalization.isValid) {
                industry = storedPersonalisationVerified.industry;
                sector = storedPersonalisationVerified.sector;
                validationComplete = true;
                setValidIndustry(storedPersonalisationVerified.isValidIndustry);
            } else {
                isDefaultStoredPersonalisation = validateOnloadStoredPersonalization.isDefault;
                handleWhenNoValidQuerystringOrStoredPersonalisationFound();
                validationComplete = true;
            }
        }

        if (validationComplete) {
            handleOnValidationComplete(industry, sector, isViaQuerystring, isDefaultStoredPersonalisation, originalUrl);
        }
    }, [getUrlIndustryAndSector.isProcessed, validateOnloadQuerystring.isProcessed, validateOnloadStoredPersonalization.isProcessed]);

    const handleWhenNoValidQuerystringOrStoredPersonalisationFound = () => {
        if (!validateOnloadQuerystring.isValid && !validateOnloadStoredPersonalization.isValid) {
            if (window.location.search) {
                navigate(generatePersonalisationClearedQueryString());
            }
        }
    };

    const getIndustryOption = (industry: string) => {
        return industryOptionList.find((ind) => ind.codeName === industry);
    };

    const getSectorOption = (industry: string, sector: string) => {
        const industryOption = getIndustryOption(industry);
        if (!industryOption) {
            return undefined;
        }

        return industryOption.sectors.find((sec) => sec.codeName === sector);
    };

    const handleOnValidationComplete = (
        industry: string,
        sector: string,
        isViaQuerystring: boolean,
        isDefaultStoredPersonalisation: boolean,
        originalUrl: string,
    ) => {
        if (
            isViaQuerystring &&
            industry === CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME &&
            sector === CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME
        ) {
            dispatch(setIndustrySector(industry, sector));
            dispatchRedux(
                setOnLoadPersonalisation({
                    state: {
                        industry: industry,
                        sector: sector,
                        url: originalUrl,
                        type: OnLoadPersonalisationType.Querystring,
                    },
                }),
            );
            setProcessed(true);
            return;
        }

        const industryOption = getIndustryOption(industry);
        const sectorOption = getSectorOption(industry, sector);

        if (!industryOption || (!sectorOption && sector !== industryOption.codeName)) {
            dispatchRedux(
                setOnLoadPersonalisation({
                    state: {
                        industry: CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME,
                        sector: CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME,
                        url: originalUrl,
                        type: isDefaultStoredPersonalisation ? OnLoadPersonalisationType.DefaultLocalStorage : OnLoadPersonalisationType.None,
                    },
                }),
            );
            setProcessed(true);
            return;
        }

        setIndustryOption(industryOption);
        setSectorOption(sectorOption);

        dispatch(setIndustrySector(industryOption.codeName, sectorOption ? sectorOption.codeName : industryOption.codeName));
        dispatchRedux(
            setOnLoadPersonalisation({
                state: {
                    industry: industryOption.codeName,
                    sector: sectorOption ? sectorOption.codeName : industryOption.codeName,
                    url: originalUrl,
                    type: isViaQuerystring ? OnLoadPersonalisationType.Querystring : OnLoadPersonalisationType.LocalStorage,
                },
            }),
        );

        setProcessed(true);
    };

    return { processed, validIndustry, industryOption, sectorOption };
}

const readyToVerifyValidation = (
    getUrlIndustryAndSector: GetUrlIndustryAndSector,
    validateOnLoadQuerystring: ValidateOnLoadQuerystring,
    validateOnLoadStoredPersonalisation: ValidateOnLoadStoredPersonalisation,
): boolean => getUrlIndustryAndSector.isProcessed && validateOnLoadQuerystring.isProcessed && validateOnLoadStoredPersonalisation.isProcessed;

const verifyValidationForQuerystring = (
    validateOnloadQuerystring: ValidateOnLoadQuerystring,
    getUrlIndustryAndSector: GetUrlIndustryAndSector,
): VerifyValidation => {
    if (!validateOnloadQuerystring.isProcessed && !getUrlIndustryAndSector.isProcessed) {
        return { isVerified: false };
    }

    let industry: string,
        sector: string,
        isValidIndustry: boolean = false;

    if (validateOnloadQuerystring.isValid) {
        const industryPersonalizationValue = parseIndustrySectorParam(getUrlIndustryAndSector.industryCode);
        const sectorPersonalizationValue = parseIndustrySectorParam(getUrlIndustryAndSector.sectorCode);

        industry = industryPersonalizationValue;
        sector = sectorPersonalizationValue;
        isValidIndustry = true;
    }

    return { isVerified: true, isValidIndustry, industry, sector };
};

const verifyValidationForStoredPersonalisation = (
    validateOnloadStoredPersonalization: ValidateOnLoadStoredPersonalisation,
    personalisationState: PersonalisationStateInterface,
    navigate: NavigateFunction,
): VerifyValidation => {
    if (!validateOnloadStoredPersonalization.isProcessed) {
        return { isVerified: false };
    }

    let industry: string,
        sector: string,
        isValidIndustry: boolean = false;

    if (validateOnloadStoredPersonalization.isValid) {
        industry = personalisationState.industryCode;
        sector = personalisationState.sectorCode;
        isValidIndustry = true;
        navigate(generateServiceQueryString(personalisationState.industryCode, personalisationState.sectorCode));
    } else if (validateOnloadStoredPersonalization.isDefault) {
        industry = personalisationState.industryCode;
        sector = personalisationState.sectorCode;
    }

    return { isVerified: true, isValidIndustry, industry, sector };
};
