import React, { useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Helmet } from 'react-helmet';
import { IContentItemsContainer, Responses } from '@kontent-ai/delivery-sdk';
import ComponentResolver from '../../ComponentResolver';
import Layout from '../../site/Layout';
import PageBody from '../pageBody/PageBody';
import { ContentItem } from '@exporter-services/common-ui';
import { setPageInfo } from '../../providers/reducers/pageSlice';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { PageItem } from '../../models/PageItem';
import HomePageHeader from '../pageHeader/HomePageHeader';
import { initTagManager } from '../../utils/tagManagerUtils';

const Home = () => {
    const [content, setContent] = useState<PageItem>();
    const [linkedItems, setlinkedItems] = useState<IContentItemsContainer>();
    const [loading, setLoading] = useState<boolean>(true);
    const dispatch = useAppDispatch();
    const handleOnNavigationDataRetrieved = (response: Responses.IListContentItemsResponse) => {
        const homepage = response.items[0] as PageItem;
        setContent(homepage);
        setlinkedItems(response.linkedItems);
        dispatch(setPageInfo({ codename: homepage.system.codename }));

        setLoading(false);
        initTagManager();
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta
                    name="description"
                    content="Tools, templates and guides to help Australian businesses succeed at exporting"
                    data-react-helmet="true"
                />
                <title>Go Global Toolkit</title>
            </Helmet>
            <LoadingOverlay active={loading} spinner text="Please wait" className="loader">
                <Layout onNavigationDataRetrieved={handleOnNavigationDataRetrieved}>
                    {content && (
                        <HomePageHeader
                            data={{
                                label: content.elements.label.value,
                                title: content.elements.title.value,
                                title_tag: content.elements.common_page_info__title_tag.value,
                                description: content.elements.abstract.value,
                                metaDescription: content.elements.common_page_info__meta_description_tag.value,
                                image: content.elements.image,
                            }}
                        />
                    )}
                    <PageBody>
                        {content?.elements.content['linkedItems'].map((item: ContentItem) => (
                            <ComponentResolver data={{ item: item, linkedItems: linkedItems }} key={item.system.id} />
                        ))}
                    </PageBody>
                </Layout>
            </LoadingOverlay>
        </>
    );
};

export default Home;
