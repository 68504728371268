import React, { BaseSyntheticEvent, FC } from 'react';
import FocusTrap from 'focus-trap-react';
import { ComButton } from '@exporter-services/common-ui';
import '../../styles/_modalDialog.scss';

interface ConfirmCancellationModalProps {
    onClose: () => void;
    onSuccess: () => void;
}

const ConfirmCancellationModal: FC<ConfirmCancellationModalProps> = ({ onClose, onSuccess }) => {
    const handleKeyDown = (event: BaseSyntheticEvent | KeyboardEvent) => {
        if ((event as KeyboardEvent).key === 'Escape') onClose();
    };

    return (
        <>
            <div className="modal-overlay" />
            <div className="modal-wrapper" aria-modal={true} tabIndex={-1} role="dialog" onKeyDown={handleKeyDown}>
                <div className="modal">
                    <div className="modal-close close-icon" onMouseDown={onClose}></div>

                    <FocusTrap>
                        <div className="p-3">
                            <h3>Please confirm that you want to cancel this webinar booking.</h3>
                            <p>If you change your mind, just rebook.</p>
                            <ComButton onClick={onSuccess}>Confirm</ComButton>
                        </div>
                    </FocusTrap>
                </div>
            </div>
        </>
    );
};

export default ConfirmCancellationModal;
