import { fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { getAccessToken } from './authUtils';
import { redirectTo, redirectToErrorPage } from '../utils/utils';
import { PageName } from '../models/PageName';

export const baseQueryWithAuth = (baseUrl: string) =>
    fetchBaseQuery({
        baseUrl,
        credentials: 'include',
        prepareHeaders: (headers) => {
            return getAccessToken().then((token) => {
                if (!headers.has('Authorization') && token) headers.set('Authorization', `Bearer ${token}`);

                return headers;
            });
        },
    });

export const handleErrorResponse = (error: FetchBaseQueryError, ignoreNotFoundError: boolean = false, ignoreServerError: boolean = false) => {
    if (error?.status === 401) redirectTo(`/${PageName.Logout}?returnTo=/${PageName.SignIn}`);
    else if (error && !((error.status === 404 && ignoreNotFoundError) || (error.status?.toString().startsWith('5') && ignoreServerError)))
        redirectToErrorPage();

    return error;
};
