import React, { useState, useEffect, useRef } from 'react';
import './NestedFilterCheckbox.scss';
import { NestedFilterItemInteraction } from './NestedFilterItemInteraction';
import { FilterItem } from './FilterItem';
import { NestedFilterItem } from './NestedFilterItem';
import { ReactComponent as FilterTick } from '../../../assets/filterTick.svg';
import { ReactComponent as Dash } from '../../../assets/minus.svg';
import { ReactComponent as ChevronUp } from '../../../assets/chevronUp.svg';
import { ENTER_KEY, SPACE_KEY } from '../../../utils/interactionHelper';
import TagManager from 'react-gtm-module';

export interface NestedFilterCheckboxProps {
    data?: {
        id: string;
        nestedFilterItem?: NestedFilterItem;
        clearState: boolean;
        open?: boolean;
        collapsedState: boolean;
        activeLozenges?: any;
        name: string;
    };
    onChange?: Function;
    onClick?: Function;
    onToggle?: Function;
}

export const NestedFilterCheckbox = (props: NestedFilterCheckboxProps) => {
    const [parentChecked, setParentChecked] = useState<boolean>(false);
    const [parentPanelCollapsed, setParentPanelCollapsed] = useState<boolean>(true);
    const [allChildItemsChecked, setAllChildItemsChecked] = useState<boolean>(false);
    const parentChevronRef = useRef(null);

    const [childrenChecked, setChildrenChecked] = useState(new Array(props.data.nestedFilterItem.subFilterItems.length).fill(false));
    const activeLozengesString = JSON.stringify(props.data.activeLozenges);
    const DL_EVENT_NAME = 'checkbox_click';

    useEffect(() => {
        if (props.data.clearState) {
            setParentChecked(false);
            setParentPanelCollapsed(true);
            setChildrenChecked(childrenChecked.map(() => false));
        }
    }, [props.data.clearState]);

    useEffect(() => {
        if (props.data.activeLozenges) {
            props.data.nestedFilterItem.subFilterItems.forEach((subItem, subFilterindex) => {
                if (!(subItem.value in props.data.activeLozenges)) {
                    if (childrenChecked[subFilterindex]) {
                        handleChildCheckItemRemoved(subItem.value, subItem.label);
                    }
                }
            });

            if (props.data.nestedFilterItem.subFilterItems.length === 0) {
                if (!(props.data.nestedFilterItem.value in props.data.activeLozenges)) {
                    if (parentChecked) {
                        handleParentCheckItemRemoved();
                    }
                }
            }
        }
    }, [activeLozengesString]);

    useEffect(() => {
        setParentPanelCollapsed(props.data.collapsedState);
    }, [props.data.collapsedState]);

    const handleChildOnKeyPress = (e: React.KeyboardEvent<HTMLLabelElement>) => {
        e.preventDefault();
        if (e.key === SPACE_KEY) {
            e.currentTarget.click();
        }
    };

    const handleParenOnKeyPress = (e: React.KeyboardEvent<HTMLLabelElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.key === SPACE_KEY) {
            e.currentTarget.click();
        } else if (e.key === ENTER_KEY) {
            parentChevronRef.current.click();
        }
    };

    const handleChildOnChange = (childItem, position) => {
        const updatedCheckedState = childrenChecked.map((item, index) => (index === position ? !item : item));
        let nestedFilterInteractions: NestedFilterItemInteraction[] = [];

        let newChildCheckedStatus = !childrenChecked[position];
        let setItem: NestedFilterItemInteraction = {
            parentValue: props.data.nestedFilterItem.value,
            childValue: childItem.value,
            selected: newChildCheckedStatus,
        };
        TriggerGTM(newChildCheckedStatus, childItem.value);

        nestedFilterInteractions.push(setItem);

        setChildrenChecked(updatedCheckedState);

        if (updatedCheckedState.includes(true)) {
            let allChildItemsChecked = updatedCheckedState.every((item) => item);
            let setParentItem: NestedFilterItemInteraction = {
                parentValue: props.data.nestedFilterItem.value,
                selected: true,
            };

            nestedFilterInteractions.push(setParentItem);
            setAllChildItemsChecked(allChildItemsChecked);
            setParentChecked(true);
        } else {
            let setParentItem: NestedFilterItemInteraction = {
                parentValue: props.data.nestedFilterItem.value,
                selected: false,
            };
            nestedFilterInteractions.push(setParentItem);
            setParentChecked(false);
            setAllChildItemsChecked(false);
        }
        props.onChange?.(nestedFilterInteractions);
    };

    const TriggerGTM = (paramStatus: boolean, paramText: string) => {
        let checkboxValue = '';

        if (paramText) {
            let position = paramText.indexOf(':');
            checkboxValue = position > -1 ? paramText.substring(0, position) : paramText;
        }

        TagManager.dataLayer({
            dataLayer: {
                event: DL_EVENT_NAME,
                dlv_checkbox_status: paramStatus,
                dlv_checkbox_text: checkboxValue,
            },
        });
    };

    const handleParentOnChange = () => {
        let toggleParentChecked = !parentChecked;
        let nestedFilterInteractions: NestedFilterItemInteraction[] = [];
        let setItem: NestedFilterItemInteraction = {
            parentValue: props.data.nestedFilterItem.value,
            selected: toggleParentChecked,
        };
        nestedFilterInteractions.push(setItem);
        setParentChecked(toggleParentChecked);
        let childrenCheckedAll = childrenChecked.map(() => toggleParentChecked);
        setChildrenChecked(childrenCheckedAll);

        props.data.nestedFilterItem.subFilterItems.forEach((childItem) => {
            let setItem: NestedFilterItemInteraction = {
                parentValue: props.data.nestedFilterItem.value,
                childValue: childItem.value,
                selected: toggleParentChecked,
            };
            nestedFilterInteractions.push(setItem);
        });
        setParentPanelCollapsed(!toggleParentChecked);
        setAllChildItemsChecked(toggleParentChecked);

        props.onChange?.(nestedFilterInteractions);
        TriggerGTM(toggleParentChecked, props.data.nestedFilterItem.value);
    };

    const handleChevronClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setParentPanelCollapsed(!parentPanelCollapsed);
    };
    const handleChevronOnKeyPress = (e) => {
        if (e.key === ENTER_KEY) {
            e.preventDefault();
            e.stopPropagation();
            setParentPanelCollapsed(!parentPanelCollapsed);
        }
    };
    const handleChildCheckItemRemoved = (itemValue: string, itemText: string) => {
        let subItemIndex = props.data.nestedFilterItem.subFilterItems.findIndex((subItem) => subItem.value === itemValue);
        handleChildOnChange({ value: itemValue, label: itemText }, subItemIndex);
    };

    const handleParentCheckItemRemoved = () => {
        handleParentOnChange();
    };

    const handleCheckBoxLabelOnClick = (e: React.MouseEvent<HTMLLabelElement>) => {
        const checkbox = e.currentTarget?.parentElement?.querySelector('input');
        if (checkbox) {
            setTimeout(() => {
                checkbox.blur();
            }, 30);
        }
    };

    return (
        <div className="nested-filter-checkbox-container" onClick={() => props.onClick()}>
            {/*----------------Parent CheckBox -----------------*/}
            <div className="filter-checkbox-item">
                <div className="filter-checkbox-field-wrapper">
                    <div className="filter-checkbox-field">
                        <input
                            type="checkbox"
                            key={props.data.id + 'Checkbox'}
                            id={props.data.id + 'Checkbox'}
                            onChange={handleParentOnChange}
                            checked={parentChecked}
                            name={props.data.name}
                        />
                        <label
                            onKeyPress={handleParenOnKeyPress}
                            htmlFor={props.data.id + 'Checkbox'}
                            className="filter-checkbox-label"
                            aria-label={
                                props.data.nestedFilterItem.label +
                                ' ' +
                                (props.data.nestedFilterItem?.count ? props.data.nestedFilterItem.count : 0) +
                                (props.data.nestedFilterItem?.count > 1 ? ' results' : 'result')
                            }
                            onClick={handleCheckBoxLabelOnClick}
                        >
                            {props.data.nestedFilterItem.label}
                            {props.data.nestedFilterItem?.count > 0 && <>&nbsp;({props.data.nestedFilterItem.count})</>}
                            {allChildItemsChecked && <FilterTick />}
                            {props.data.nestedFilterItem.subFilterItems.length > 0 && !allChildItemsChecked && <Dash />}
                        </label>
                    </div>
                    <div className="filter-checkbox-state">
                        {props.data.nestedFilterItem.subFilterItems?.length > 0 && (
                            <div
                                ref={parentChevronRef}
                                className="nested-filter-checkbox-chevron"
                                onClick={handleChevronClick}
                                onKeyPress={handleChevronOnKeyPress}
                                tabIndex={0}
                                role="button"
                                aria-expanded={!parentPanelCollapsed}
                            >
                                <ChevronUp className={parentPanelCollapsed ? '' : 'svg-open'}></ChevronUp>
                            </div>
                        )}
                    </div>
                </div>
                {/*---------------Nested checkboxes---------------------*/}
                <div
                    className={
                        !props.data.nestedFilterItem.subFilterItems || props.data.nestedFilterItem.subFilterItems.length < 1 || parentPanelCollapsed
                            ? 'hide-element'
                            : 'filter-inner-checkbox-container'
                    }
                    key={props.data.id + 'parentSubPanel'}
                    id={props.data.id + 'parentSubPanel'}
                >
                    {props.data.nestedFilterItem.subFilterItems?.map((item: FilterItem, index) => (
                        <div className="filter-checkbox-item-inner" key={item.value + 'Div' + index}>
                            <div className="filter-checkbox-field-inner">
                                <input
                                    type="checkbox"
                                    key={item.value + 'Checkbox' + index}
                                    id={item.value + 'Checkbox' + index}
                                    onChange={() => handleChildOnChange(item, index)}
                                    checked={childrenChecked[index]}
                                    name={props.data.name + '_' + props.data.id}
                                />
                                <label
                                    htmlFor={item.value + 'Checkbox' + index}
                                    className="mini filter-checkbox-label"
                                    onKeyPress={handleChildOnKeyPress}
                                    aria-label={item.label + ' ' + (item.count ? item.count : 0) + (item.count > 1 ? ' results' : 'result')}
                                    onClick={handleCheckBoxLabelOnClick}
                                >
                                    {item.label}
                                    {item.count > 0 && <>&nbsp;({item.count})</>}
                                    <FilterTick />
                                </label>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
