import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import { ContentItem } from '@exporter-services/common-ui';
import ComponentResolver from '../../ComponentResolver';
import { useAppSelector } from '../../hooks/reduxHooks';
import { MvTaxesAndDutiesInfo, UserGeolocationInfo } from '../../models/ReduxModels';
import { getContainerParentName } from '../../utils/getParentContainerName';
import { PageName } from '../../models/PageName';

interface FilteredLawsAndRegsDutiesandtaxesMarketResultsProps {
    match?: any;
    onLoad?: (dataAvailable: boolean, title: string) => void;
    onLoadDescription?: (dataAvailable: boolean, title: string) => void;
    data: {
        item: ContentItem;
        linkedItems: IContentItemsContainer;
    };
}

const FilteredLawsAndRegsDutiesandtaxesMarketResults = (props: FilteredLawsAndRegsDutiesandtaxesMarketResultsProps) => {
    let codeNames: Array<string>;
    const [itemsToRender, setItemsToRender] = useState([]);
    const [mVTariffsFiltered, setMVTariffsFiltered] = useState<boolean>(false);
    const [mVTaxesFiltered, setMVTaxesFiltered] = useState<boolean>(false);
    const [filteredMVTaxesItems, setfilteredMVTaxesItems] = useState<Array<string>>([]);
    const [filteredMVTariffsItems, setfilteredMVTariffsItems] = useState<Array<string>>([]);
    const mvTaxesAndDutiesInfo = useAppSelector<MvTaxesAndDutiesInfo>((state) => state.mvLawsAndRegs.mvTaxesAndDutiesInfo);
    const geolocationInfo = useAppSelector<UserGeolocationInfo>((state) => state.user.geolocationInfo);
    const navigate = useNavigate();

    useEffect(() => {
        codeNames = props.data.item.elements.linked_items['linkedItems'].map((m) => m.system.codename);
    });

    useEffect(() => {
        if (!geolocationInfo.retrieved) return;
        if (geolocationInfo.data.isOffshore) navigate(`/${PageName.PricingCostsAndFinance}/${PageName.FindTariffs}`);
    }, [geolocationInfo.retrieved]);

    useEffect(() => {
        if (mVTariffsFiltered && mVTaxesFiltered) {
            const data = props.data.item.elements.linked_items['linkedItems'].filter(
                (m) => !filteredMVTariffsItems.includes(m.system.codename) && !filteredMVTaxesItems.includes(m.system.codename),
            );
            setItemsToRender(data);
        }
    }, [mVTariffsFiltered, mVTaxesFiltered, filteredMVTariffsItems, filteredMVTaxesItems, props.data.item.elements.linked_items]);

    // Filter out MV Tariffs and MV Taxes components
    useEffect(() => {
        const getTariffsContainerParentNames = (): Array<string> => getContainerParentName('tariffs', props.data.linkedItems, codeNames);
        const getTaxesContainerParentNames = (): Array<string> => getContainerParentName('taxes', props.data.linkedItems, codeNames);

        if (mvTaxesAndDutiesInfo.retrieved) {
            if (!(mvTaxesAndDutiesInfo.data?.Duties?.length > 0)) {
                const codeNames = getTariffsContainerParentNames();
                setfilteredMVTariffsItems(codeNames);
            }
            setMVTariffsFiltered(true);

            if (!(mvTaxesAndDutiesInfo.data?.Taxes?.length > 0)) {
                const codeNames = getTaxesContainerParentNames();
                setfilteredMVTaxesItems(codeNames);
            }
            setMVTaxesFiltered(true);
        }
    }, [mvTaxesAndDutiesInfo.retrieved]);

    return (
        <Row className="filtered-results">
            <Col>
                {itemsToRender.map((a: ContentItem) => {
                    return (
                        <ComponentResolver
                            data={{ item: a, linkedItems: props.data.linkedItems }}
                            key={a.system.id}
                            match={props.match}
                            onLoad={props.onLoad}
                            type={props.data.item.elements.market_multiple_choice?.value?.[0]?.codename}
                        />
                    );
                })}
            </Col>
        </Row>
    );
};

export default FilteredLawsAndRegsDutiesandtaxesMarketResults;
