import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import GlobalState, { ProductServiceSelectionState } from '../globalState/globalState';
import { OnLoadPersonalisationInfo } from '../../models/ReduxModels';
import { OnLoadPersonalisationType } from '../../models/enums';

const initState: ProductServiceSelectionState = {
    productServiceSelectionInfo: { hsCode: '', sectorCode: '', industryCode: '', market: '' },
    onLoadPersonalisationInfo: {
        isSet: false,
        url: null,
        sector: '',
        industry: '',
        type: OnLoadPersonalisationType.None,
    },
};

type SetOnLoadPersonalisationRequest = {
    industry: string;
    sector: string;
    url: string;
    type: OnLoadPersonalisationType;
};

type SetIndustrySectorRequest = {
    industryCode: string;
    sectorCode: string;
};

type SetHsCodeRequest = {
    hsCode: string;
};

type SetIndustrySectorHsCodeAndMarketRequest = {
    industryCode: string;
    sectorCode: string;
    hsCode: string;
    market: string;
};

type SetIndustrySectorAndHsCodeRequest = {
    industryCode: string;
    sectorCode: string;
    hsCode: string;
};
export const productServiceSelectionSlice = createSlice({
    name: 'productServiceSelection',
    initialState: initState,
    reducers: {
        setIndustrySectorInfo(state: ProductServiceSelectionState, action: PayloadAction<SetIndustrySectorRequest>) {
            state.productServiceSelectionInfo = {
                hsCode: state.productServiceSelectionInfo.hsCode,
                sectorCode: action.payload.sectorCode,
                industryCode: action.payload.industryCode,
                market: state.productServiceSelectionInfo.market,
            };
        },
        setHsCodeInfo(state: ProductServiceSelectionState, action: PayloadAction<SetHsCodeRequest>) {
            state.productServiceSelectionInfo = {
                hsCode: action.payload.hsCode,
                sectorCode: state.productServiceSelectionInfo.sectorCode,
                industryCode: state.productServiceSelectionInfo.industryCode,
                market: state.productServiceSelectionInfo.market,
            };
        },
        purgeState(state: ProductServiceSelectionState) {
            state.productServiceSelectionInfo = {
                hsCode: '',
                sectorCode: '',
                industryCode: '',
                market: '',
            };
        },
        setIndustrySectorHsCdeAndMarketInfo(state: ProductServiceSelectionState, action: PayloadAction<SetIndustrySectorHsCodeAndMarketRequest>) {
            state.productServiceSelectionInfo = {
                hsCode: action.payload.hsCode,
                sectorCode: action.payload.sectorCode,
                industryCode: action.payload.industryCode,
                market: action.payload.market,
            };
        },
        setIndustrySectorAndHsCodeInfo(state: ProductServiceSelectionState, action: PayloadAction<SetIndustrySectorAndHsCodeRequest>) {
            state.productServiceSelectionInfo = {
                hsCode: action.payload.hsCode,
                sectorCode: action.payload.sectorCode,
                industryCode: action.payload.industryCode,
                market: state.productServiceSelectionInfo.market,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setOnLoadPersonalisation.fulfilled, (state, action) => {
            const payload = action.payload as SetOnLoadPersonalisationRequest;
            state.onLoadPersonalisationInfo = {
                ...state.onLoadPersonalisationInfo,
                url: payload.url,
                industry: payload.industry,
                sector: payload.sector,
                type: payload.type,
                isSet: true,
            } as OnLoadPersonalisationInfo;
        });
    },
});

const { setIndustrySectorHsCdeAndMarketInfo, setIndustrySectorAndHsCodeInfo, setIndustrySectorInfo, setHsCodeInfo, purgeState } =
    productServiceSelectionSlice.actions;

export const setIndustrySector = (industryCode: string, sectorCode: string) => {
    return (dispatch) => {
        dispatch(setIndustrySectorInfo({ industryCode, sectorCode } as SetIndustrySectorRequest));
    };
};
export const setHsCode = (hsCode: string) => {
    return (dispatch) => {
        dispatch(setHsCodeInfo({ hsCode } as SetHsCodeRequest));
    };
};
export const clearProductsServicesSelection = () => {
    return (dispatch) => {
        dispatch(purgeState());
    };
};
export const setIndustrySectorHsCodeAndMarket = (industryCode: string, sectorCode: string, hsCode: string, market: string) => {
    return (dispatch) => {
        dispatch(
            setIndustrySectorHsCdeAndMarketInfo({
                industryCode,
                sectorCode,
                hsCode,
                market,
            } as SetIndustrySectorHsCodeAndMarketRequest),
        );
    };
};
export const setIndustrySectorAndHsCode = (industryCode: string, sectorCode: string, hsCode: string) => {
    return (dispatch) => {
        dispatch(setIndustrySectorAndHsCodeInfo({ industryCode, sectorCode, hsCode } as SetIndustrySectorAndHsCodeRequest));
    };
};

export const setOnLoadPersonalisation = createAsyncThunk(
    'onLoadPersonalisation/setOnLoadPersonalisation',
    async (request: { state: SetOnLoadPersonalisationRequest }, thunkAPI) => {
        if ((thunkAPI.getState() as GlobalState).productServiceSelection.onLoadPersonalisationInfo.isSet) {
            return thunkAPI.rejectWithValue(true);
        }
        return await new Promise<SetOnLoadPersonalisationRequest>((resolve) => {
            thunkAPI.dispatch(setIndustrySector(request.state.industry, request.state.sector));
            resolve(request.state);
        });
    },
);

const productServiceSelectionReducer = productServiceSelectionSlice.reducer;

export default productServiceSelectionReducer;
