import { client } from '../config';
import { StrongMarket } from '../models/StrongMarket';
import { ContentItem } from '@exporter-services/common-ui';
import { MarketSearchToolSearchType } from '../models/enums';
import { MarketSearchRequest } from '../models/ReduxModels';

interface MarketSearchResponse {
    Results: StrongMarket[] | ContentItem[];
}

export class MarketService {
    public getMarketName = (marketCode: string): Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            fetch('/api/markets/getmarketname?query=' + marketCode)
                .then((response) => response.text())
                .then((data) => {
                    resolve(data);
                })
                .catch((err) => reject(err));
        });
    };

    private getStrongMarketSearchData = (hsCode: string): Promise<StrongMarket[]> => {
        return new Promise((resolve, reject) => {
            fetch(`/api/strongmarkets?$filter=(Code eq '${hsCode}')`)
                .then((response) => response.json())
                .then((data) => {
                    resolve(data.value);
                })
                .catch(() => {
                    reject();
                });
        });
    };

    private getMarketsSearchData = (sector: string): Promise<ContentItem[]> => {
        return new Promise<ContentItem[]>((resolve) => {
            let marketProfileQuery = client.items().equalsFilter('system.type', 'market_profile').notEqualsFilter('system.workflow_step', 'archived');

            if (sector) marketProfileQuery.containsFilter('elements.industrysectorcodesnippet__sector', [sector]);

            marketProfileQuery
                .depthParameter(2)
                .toPromise()
                .then((response) => {
                    resolve(response.data.items);
                });
        });
    };

    public getMarketSearch = (request: MarketSearchRequest): Promise<MarketSearchResponse> => {
        const requestPromise = new Promise<StrongMarket[] | ContentItem[]>((resolve, reject) => {
            switch (request.Type) {
                case MarketSearchToolSearchType.StrongMarkets:
                    this.getStrongMarketSearchData(request.StrongMarketSearchRequest.Commodity)
                        .then((resp) => {
                            resolve(resp);
                        })
                        .catch(() => {
                            reject();
                        });
                    break;
                case MarketSearchToolSearchType.Markets:
                    resolve(this.getMarketsSearchData(request.MarketSearchRequest?.Sector));
                    break;
            }
        });

        return new Promise<MarketSearchResponse>((resolve) => {
            requestPromise.then((response) => {
                resolve({ Results: response });
            });
        });
    };

    public getTradeAgreementMarkets = (): Promise<string[]> => {
        return new Promise<string[]>((resolve, reject) => {
            client
                .items()
                .equalsFilter('system.type', 'doing_business_resource')
                .notEqualsFilter('system.workflow_step', 'archived')
                .anyFilter('elements.resource_type', ['free_trade_agreements'])
                .toPromise()
                .then((response) => {
                    const freeTradeAgreementCodes = response.data.items
                        .map((item) => {
                            return item?.elements?.marketcodesnippet__market.value?.length > 0
                                ? item.elements.marketcodesnippet__market.value.map((market) => market.codename)
                                : undefined;
                        })
                        .filter(Boolean);
                    resolve(freeTradeAgreementCodes.flat());
                })
                .catch((err) => reject(err));
        });
    };

    public getMarketImages = (): Promise<ContentItem[]> => {
        return new Promise<ContentItem[]>((resolve, reject) => {
            client
                .items()
                .equalsFilter('system.type', 'market_image')
                .notEqualsFilter('system.workflow_step', 'archived')
                .depthParameter(2)
                .toPromise()
                .then((response) => {
                    resolve(response.data.items);
                })
                .catch((err) => reject(err));
        });
    };
}
