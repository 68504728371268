import React, { BaseSyntheticEvent } from 'react';
import FocusTrap from 'focus-trap-react';
import { AbnForm } from './AbnForm';
import '../../styles/_modalDialog.scss';

interface AbnModalProps {
    onClose: () => void;
    onSuccess: () => void;
}

export const AbnModal = (props: AbnModalProps) => {
    const handleKeyDown = (event: BaseSyntheticEvent | KeyboardEvent) => {
        if ((event as KeyboardEvent).key === 'Escape') props.onClose();
    };

    return (
        <>
            <div className="modal-overlay" />
            <div className="modal-wrapper" aria-modal={true} tabIndex={-1} role="dialog" onKeyDown={handleKeyDown}>
                <div className="modal">
                    <div className="modal-close close-icon" onMouseDown={props.onClose}></div>

                    <FocusTrap>
                        <div>
                            <AbnForm onSuccess={props.onSuccess} />
                        </div>
                    </FocusTrap>
                </div>
            </div>
        </>
    );
};
