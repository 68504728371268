import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    CurrentMarketNameInfo,
    CurrentMarketSearchToolSearchData,
    GetCurrentMarketNameRequest,
    MarketTradeAgreementsInfo,
    MarketImagesInfo,
    MarketSearchRequest,
} from '../../models/ReduxModels';
import GlobalState, { MarketState } from '../globalState/globalState';

const initState: MarketState = {
    currentMarketNameInfo: { retrieved: false, pending: false },
    currentMarketSearchResultsInfo: undefined,
    pendingMarketSearchInfo: undefined,
    marketTradeAgreementsInfo: undefined,
    marketImagesInfo: undefined,
};

const marketSlice = createSlice({
    name: 'market',
    initialState: initState,
    reducers: {
        pendingCurrentMarketNameInfo(state) {
            state.currentMarketNameInfo.pending = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCurrentMarketNameInfo.fulfilled, (state, action) => {
            state.currentMarketNameInfo = action.payload;
        });
        builder.addCase(getMarketSearchToolSearchData.pending, (state, action) => {
            state.pendingMarketSearchInfo = { timeTag: action.meta.arg.TimeTag };
        });
        builder.addCase(getMarketSearchToolSearchData.fulfilled, (state, action) => {
            state.currentMarketSearchResultsInfo = action.payload;
            state.pendingMarketSearchInfo = undefined;
        });
        builder.addCase(getKenticoMarketTradeAgreementsInfo.fulfilled, (state, action) => {
            state.marketTradeAgreementsInfo = action.payload;
        });
        builder.addCase(getKenticoMarketImageInfo.fulfilled, (state, action) => {
            state.marketImagesInfo = action.payload;
        });
    },
});

export const getCurrentMarketNameInfo = createAsyncThunk(
    'market/getCurrentMarketNameInfo',
    async (request: { reactRouterDomMatch: GetCurrentMarketNameRequest }, thunkAPI) => {
        const state = thunkAPI.getState() as GlobalState;
        if (
            state.market.currentMarketNameInfo.retrieved ||
            state.market.currentMarketNameInfo.pending ||
            !request.reactRouterDomMatch.params.market
        ) {
            return thunkAPI.rejectWithValue(true);
        }
        thunkAPI.dispatch(marketSlice.actions.pendingCurrentMarketNameInfo());
        return await new Promise<CurrentMarketNameInfo>((resolve) => {
            (thunkAPI.getState() as GlobalState).singletonServices.marketService
                .getMarketName(request.reactRouterDomMatch.params.market)
                .then((resp) => {
                    resolve({ retrieved: true, pending: false, data: resp });
                })
                .catch(() => {
                    resolve({ retrieved: true, pending: false });
                });
        });
    },
);

export const getMarketSearchToolSearchData = createAsyncThunk(
    'market/getMarketSearchToolSearchData',
    async (request: MarketSearchRequest, thunkAPI) => {
        return await new Promise<CurrentMarketSearchToolSearchData>((resolve) => {
            (thunkAPI.getState() as GlobalState).singletonServices.marketService
                .getMarketSearch(request)
                .then((resp) => {
                    resolve({ type: request.Type, results: resp.Results, timeTag: request.TimeTag });
                })
                .catch(() => {
                    resolve({ type: request.Type, results: [], timeTag: undefined });
                });
        });
    },
);

export const getKenticoMarketTradeAgreementsInfo = createAsyncThunk('market/getMarketTradeAgreementsInfo', async (request: null, thunkAPI: any) => {
    const state = thunkAPI.getState() as GlobalState;
    return await new Promise<MarketTradeAgreementsInfo>((resolve) => {
        state.singletonServices.marketService
            .getTradeAgreementMarkets()
            .then((resp) => {
                resolve({ retrieved: true, pending: false, data: Array.from(new Set(resp)) });
            })
            .catch(() => {
                resolve({ retrieved: true, pending: false });
            });
    });
});

export const getKenticoMarketImageInfo = createAsyncThunk('market/getMarketImagesInfo', async (request: null, thunkAPI: any) => {
    const state = thunkAPI.getState() as GlobalState;
    return await new Promise<MarketImagesInfo>((resolve) => {
        state.singletonServices.marketService
            .getMarketImages()
            .then((resp) => {
                resolve({ retrieved: true, pending: false, data: resp });
            })
            .catch(() => {
                resolve({ retrieved: true, pending: false });
            });
    });
});

const marketReducer = marketSlice.reducer;

export default marketReducer;
