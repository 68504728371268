import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { personalisationContext } from '../../providers/personalisation';
import { clearSelection, setIndustrySector } from '../../providers/personalisation/reducer';
import usePersonalisedPageOnLoadPersonalisation from '../../hooks/useOnLoadPersonalisation';
import { generatePersonalisationClearedQueryString, generateServiceQueryString } from '../../utils/personalisationUtils';
import { setIndustrySector as setIndustrySectorProductService } from '../../providers/reducers/productServiceSelectionSlice';
import { CommonConstants } from '../../CommonConstants';
import { useAppDispatch } from '../../hooks/reduxHooks';
import PersonalisedPageSearchPanel from './PersonalisedPageSearchPanel';
import { ContentItem } from '@exporter-services/common-ui';
import ResolveLink from '../../controls/resolveLink/ResolveLink';
import { IndustryOption, SectorOption } from '../../models/IndustrySector';
import { isHtmlEmpty } from '../../utils/utils';

interface PersonalisedPageSearchContainerProps {
    data: {
        item: ContentItem;
    };
}

const PersonalisedPageSearchContainer = (props: PersonalisedPageSearchContainerProps) => {
    const { dispatch } = useContext(personalisationContext);
    const [loading, setLoading] = useState<boolean>(true);
    const [industries, setIndustries] = useState<IndustryOption[]>([]);

    const [selectedIndustry, setSelectedIndustry] = useState<IndustryOption>(null);
    const [selectedSector, setSelectedSector] = useState<SectorOption>(null);

    const scrollRef = useRef(null);

    const [completedOnLoadSelectionCheck, setCompletedOnLoadSelectionCheck] = useState<boolean>(false);
    const [industriesRetrieved, setIndustriesRetrieved] = useState<boolean>(false);

    const navigate = useNavigate();
    const reduxDispatch = useAppDispatch();
    const onLoadPersonalisation = usePersonalisedPageOnLoadPersonalisation(industries, industriesRetrieved);

    const extractIndustries = () => {
        let industriesToCollect: IndustryOption[] = [];

        if (props.data.item.elements.personalisation_options['linkedItems'][0]?.elements?.dropdown_item['linkedItems']) {
            props.data.item.elements.personalisation_options['linkedItems'][0]?.elements?.dropdown_item['linkedItems'].forEach((i) => {
                let sectors: SectorOption[] = [];

                i.elements.filtered_component['linkedItems']
                    .filter((fc) => fc?.elements.industry_sector?.value?.length > 0)
                    .forEach((fc) => {
                        let sector: SectorOption = {
                            title: fc?.elements.title?.value,
                            codeName: fc?.elements.industry_sector?.value[0]?.codename,
                        };

                        sectors.push(sector);
                    });

                let industry: IndustryOption = {
                    title: i.elements.title?.value,
                    codeName: i.elements.industry_sector?.value[0]?.codename,
                    sectors: [...sectors],
                };

                industriesToCollect.push(industry);
                setIndustries(industriesToCollect);
            });

            setIndustriesRetrieved(true);
        }
    };

    useEffect(() => {
        extractIndustries();

        setLoading(false);
    }, []);

    useEffect(() => {
        if (!onLoadPersonalisation.processed) {
            return;
        }

        if (onLoadPersonalisation.validIndustry) {
            setSelectedIndustry(onLoadPersonalisation.industryOption);
            setSelectedSector(onLoadPersonalisation.sectorOption);
        }
        setCompletedOnLoadSelectionCheck(true);
    }, [onLoadPersonalisation.processed]);

    const clearFilter = () => {
        setSelectedIndustry(null);
        setSelectedSector(null);
        dispatch(clearSelection());
        reduxDispatch(
            setIndustrySectorProductService(
                CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME,
                CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME,
            ),
        );
        navigate(generatePersonalisationClearedQueryString());
    };

    const applyFilter = (event: React.MouseEvent<HTMLButtonElement>, panelSelectedIndustry: IndustryOption, panelSelectedSector: SectorOption) => {
        let sectorCode = panelSelectedSector === null ? panelSelectedIndustry.codeName : panelSelectedSector.codeName;
        let localSelectedIndustry: IndustryOption = selectedIndustry;

        if (!selectedIndustry || panelSelectedIndustry.codeName !== selectedIndustry.codeName) {
            setSelectedIndustry(panelSelectedIndustry);
            localSelectedIndustry = panelSelectedIndustry;
        }

        if (!selectedSector || sectorCode !== selectedSector.codeName) {
            setSelectedSector(panelSelectedSector);
        }

        dispatch(setIndustrySector(localSelectedIndustry.codeName, sectorCode));
        reduxDispatch(setIndustrySectorProductService(localSelectedIndustry.codeName, sectorCode));

        const querystring = generateServiceQueryString(
            localSelectedIndustry.codeName,
            panelSelectedSector?.codeName ? panelSelectedSector.codeName : localSelectedIndustry.codeName,
        );
        navigate(querystring);
        scrollToTop();
    };

    const getId = (title: string): string =>
        title
            ?.replace(/\s+/g, '-')
            .replace(/[^\w-]/g, '')
            .toLowerCase();

    const scrollToTop = () => {
        scrollRef.current.scrollIntoView();
    };

    const getMessageHtml = () => {
        let sectorLabel = getIndustrySectorLabel();

        if (sectorLabel) {
            if (props.data.item.elements?.personalisation_text_messages__results_specific_message?.value) {
                const splits = props.data.item.elements.personalisation_text_messages__results_specific_message.value.split('{{sector}}');
                return (
                    <p className="results-message">
                        {splits[0]}
                        <span className="semi-bold">{sectorLabel}</span>
                        {splits[1]}
                    </p>
                );
            } else return <></>;
        } else {
            return <p className="results-message">{props.data.item.elements.personalisation_text_messages__results_not_specific_message?.value}</p>;
        }
    };

    const getIndustrySectorLabel = () => selectedSector?.title || selectedIndustry?.title || null;

    const titleText = props.data.item.elements.personalisation_text_messages__title?.value;
    const description = props.data.item.elements.personalisation_text_messages__description;

    return (
        <div id={getId(titleText)}>
            {titleText && (
                <>
                    <h2>{titleText}</h2>
                    {!isHtmlEmpty(description?.value) && (
                        <div>
                            <ResolveLink data={{ item: { description } }} type={description?.type} />
                        </div>
                    )}
                </>
            )}
            {!loading && completedOnLoadSelectionCheck && (
                <PersonalisedPageSearchPanel
                    data={{
                        industries: industries,
                        selectedIndustry: selectedIndustry,
                        selectedSector: selectedSector,
                    }}
                    onClearFilter={clearFilter}
                    onApplyFilter={applyFilter}
                />
            )}
            <div ref={scrollRef}></div>
            <hr />
            {getMessageHtml()}
        </div>
    );
};

export default PersonalisedPageSearchContainer;
