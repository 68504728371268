import { LocalStorageCache, WrappedCacheEntry } from '@auth0/auth0-spa-js';
import { CommonConstants } from '../CommonConstants';
import { UserProfile } from '../models/UserProfile';

export const getAccessToken = () => {
    const auth0LocalStorage = new LocalStorageCache();
    const authDataKey = auth0LocalStorage.allKeys().find((key) => key.includes('openid'));

    return new Promise((resolve) => resolve(auth0LocalStorage.get(authDataKey))).then((authData: WrappedCacheEntry) => authData?.body?.access_token);
};

export const clearAuthData = () => {
    clearUserProfile();

    const auth0LocalStorage = new LocalStorageCache();
    auth0LocalStorage.allKeys().forEach((key) => auth0LocalStorage.remove(key));
};

export const getUserProfile = () => JSON.parse(localStorage.getItem(CommonConstants.LOCALSTORAGE_USER_KEY)) as UserProfile;

export const setUserProfile = (data) => localStorage.setItem(CommonConstants.LOCALSTORAGE_USER_KEY, JSON.stringify(data));

export const clearUserProfile = () => localStorage.removeItem(CommonConstants.LOCALSTORAGE_USER_KEY);
