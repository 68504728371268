import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth, handleErrorResponse } from '../../utils/httpUtils';
import { WebinarEvent } from '../../models/WebinarEvent';
import { EventRegistration } from '../../models/EventRegistration';

export const eventsApi = createApi({
    reducerPath: 'eventsApi',
    baseQuery: baseQueryWithAuth('/api/events'),
    endpoints: (builder) => ({
        getEventDetailsUnauthenticated: builder.query<WebinarEvent, { eventId: string }>({
            query: ({ eventId }) => `/getUnauthenticated/${eventId}`,
            transformResponse: (response: WebinarEvent) => response,
        }),
        getEventDetails: builder.query<WebinarEvent, { eventId: string }>({
            query: ({ eventId }) => `/get/${eventId}`,
            transformResponse: (response: WebinarEvent) => response,
            transformErrorResponse: (error) => handleErrorResponse(error),
        }),
        getRegistrations: builder.query<EventRegistration[], {}>({
            query: () => '/registrations',
            transformResponse: (response: EventRegistration[]) => response,
            transformErrorResponse: (error) => handleErrorResponse(error),
        }),
        registerForEvent: builder.mutation({
            query: (eventId) => ({
                url: `/registration/${eventId}`,
                method: 'POST',
            }),
            transformErrorResponse: (error) => handleErrorResponse(error),
        }),
        cancelEventRegistration: builder.mutation({
            query: (eventId) => ({
                url: `/cancelRegistration/${eventId}`,
                method: 'DELETE',
            }),
            transformErrorResponse: (error) => handleErrorResponse(error),
        }),
        checkinForEvent: builder.mutation({
            query: (eventId) => ({
                url: `/checkin/${eventId}`,
                method: 'POST',
            }),
            transformErrorResponse: (error) => handleErrorResponse(error, true, true),
        }),
    }),
});

export const {
    useGetEventDetailsUnauthenticatedQuery,
    useGetEventDetailsQuery,
    useGetRegistrationsQuery,
    useRegisterForEventMutation,
    useCancelEventRegistrationMutation,
    useCheckinForEventMutation,
} = eventsApi;

export default eventsApi.reducer;
