import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export const formatDate = (date: Date) => formatDateAsAEST(date, 'D MMMM YYYY');

export const formatDateShowDayAndMonth = (date: Date) => formatDateAsAEST(date, 'D MMMM');

const formatDateAsAEST = (date: Date, format: string) => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    return dayjs.tz(date, 'Australia/Sydney').format(format);
};

export const formatEventStartDateEndDate = (startDateStr: string, endDateStr: string): string => {
    if (startDateStr) {
        let startDate = new Date(startDateStr);

        if (endDateStr) {
            let endDate = new Date(endDateStr);
            let startDateMonth = startDate.getMonth();
            let endDateMonth = endDate.getMonth();
            let endDateLabel = formatDate(endDate);

            return startDateMonth === endDateMonth
                ? `${startDate.getDate()} to ${endDateLabel}`
                : `${formatDateShowDayAndMonth(startDate)} to ${endDateLabel}`;
        }

        return formatDate(startDate);
    }

    return '';
};
