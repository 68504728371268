import React, { useEffect, useMemo, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import SyncLoader from 'react-spinners/SyncLoader';
import '../navigateFurther/NavigateFurther.scss';
import { HscodeHierarchy } from '../../../models/HscodeHierarchy';
import { LawsAndRegsDataSourceType } from '../../../models/enums';
import { CommodityHierarchyApiResult, useGetMVCommodityHierarchyQuery } from '../../../providers/reducers/hierarchyApi';
import NarrowDownSearchTree from './NarrowDownSearchTree';

interface TariffFinderNarrowDownSearchTreeProps {
    data: {
        sector: string;
        market: string;
    };
    onSectorSelected: (sectorSelected: string) => void;
    onDataRetrieved?: (data: HscodeHierarchy[]) => void;
}

const useMVCommodityHierarchy = (sector: string, market: string): CommodityHierarchyApiResult => {
    const { data, isLoading, isError } = useGetMVCommodityHierarchyQuery({ sector, market });
    const [internalData, setInternalData] = useState<HscodeHierarchy[]>(undefined);

    const memoData = useMemo(() => {
        if (JSON.stringify(internalData) !== JSON.stringify(data)) {
            setInternalData(data);
            return data;
        }

        return internalData;
    }, [data, internalData]);

    return { data: memoData, isLoading, isError };
};

const TariffFinderSearchNarrowDownSearchTree = (props: TariffFinderNarrowDownSearchTreeProps) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [showTree, setShowTree] = useState<boolean>(true);
    const [currentCode, setCurrentCode] = useState<string>();

    const commodityHierarchy = useMVCommodityHierarchy(props.data.sector, props.data.market);

    //#region showComponent
    useEffect(() => {
        if (props.data.sector !== currentCode) {
            setLoading(true);
        }

        setCurrentCode(props.data.sector);
    }, [props.data.sector]);

    useEffect(() => {
        if (commodityHierarchy.isLoading) {
            setLoading(true);
            setShowTree(false);
        } else {
            setLoading(false);
            setShowTree(commodityHierarchy.data[0]?.Children.length > 0);
            props.onDataRetrieved(commodityHierarchy.data);
        }
    }, [commodityHierarchy.data, commodityHierarchy.isLoading]);

    //#endregion

    //#region page event methods
    const handleOnLoadChange = (isLoading: boolean) => {
        setLoading(isLoading);
    };

    const handleOnShowChange = (show: boolean) => {
        setShowTree(show);
    };
    //#endregion

    return (
        <>
            <LoadingOverlay active={loading} spinner={<SyncLoader />} text="Please wait" className="loader">
                {showTree && (
                    <NarrowDownSearchTree
                        data={{
                            dataSourceType: LawsAndRegsDataSourceType.MV,
                            market: props.data.market,
                            sector: props.data.sector,
                            hierarchy: commodityHierarchy?.data,
                        }}
                        onLoadChange={handleOnLoadChange}
                        onShowChange={handleOnShowChange}
                        onNarrowDownSearchItemSelected={props.onSectorSelected}
                    />
                )}
            </LoadingOverlay>
        </>
    );
};

export default TariffFinderSearchNarrowDownSearchTree;
