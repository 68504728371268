import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { DeliveryClient, ILink, createDeliveryClient } from '@kontent-ai/delivery-sdk';
import { ContentItem } from '../models';

export const instantiateKenticoClient = (kenticoProjectId: string, kenticoUsePreviewMode: boolean, kenticoPreviewApiKey: string): DeliveryClient => {
    let client;

    if (kenticoUsePreviewMode) {
        client = createDeliveryClient({
            environmentId: kenticoProjectId,
            previewApiKey: kenticoPreviewApiKey,
            defaultQueryConfig: {
                usePreviewMode: true, // Queries the Delivery Preview API.
            },
        });
    } else {
        client = createDeliveryClient({
            environmentId: kenticoProjectId,
        });
    }

    return client;
};

export const getParentPage = (client: DeliveryClient, codeName: string) => {
    return new Promise<ContentItem>((resolve, reject) => {
        client
            .items()
            .containsFilter('elements.subpages', [codeName])
            .notEmptyFilter('elements.subpages')
            .limitParameter(1)
            .toPromise()
            .then((response) => {
                if (!response.data.items?.[0]) {
                    resolve(undefined);
                    return;
                }
                resolve(response.data.items[0] as any);
            })
            .catch(() => {
                reject();
            });
    });
};

export const getParentPath = (
    client: DeliveryClient,
    codeName: string,
    parentPath: string,
    next: Dispatch<SetStateAction<string>> | ((value: string | PromiseLike<string>) => void),
) => {
    client
        .items()
        .containsFilter('elements.subpages', [codeName])
        .notEmptyFilter('elements.subpages')
        .elementsParameter(['url'])
        .limitParameter(1)
        .toPromise()
        .then((response) => {
            let result: string = null;

            if (response.data.items?.length > 0 && response.data.items[0].elements.url) {
                result = parentPath ? `${response.data.items[0].elements.url.value}/${parentPath}` : response.data.items[0].elements.url.value;
                getParentPath(client, response.data.items[0].system.codename, result, next);
            } else if (parentPath) {
                next(parentPath);
            }
        })
        .catch(() => null);
};

export const ResolveContentLink = (client: DeliveryClient, link: ILink) => {
    const [parentPath, setParentPath] = useState<string>(null);

    useEffect(() => {
        if (
            link.type === 'page' ||
            link.type === 'news_article_page' ||
            link.type === 'case_study_page' ||
            link.type === 'blog_article_page' ||
            link.type === 'sector_landing_page' ||
            link.type === 'homepage' ||
            link.type === 'event_page'
        ) {
            getParentPath(client, link.codename, null, setParentPath);
        }
    }, [link]);

    //In case parent cant be retrieved or if resolving to a super parent
    if (parentPath) {
        return { url: `/${parentPath}/${link.urlSlug}` };
    } else {
        return { url: `/${link.urlSlug}` };
    }
};
