import globalReducer from '../reducers/globalReducer';
import { configureStore } from '@reduxjs/toolkit';
import { LawsAndRegsPageService } from '../../services/LawsAndRegsPageService';
import { hierarchyApi } from '../reducers/hierarchyApi';
import { marketsApi } from '../reducers/marketsApi';
import { mvLawsAndRegsApi } from '../reducers/mvLawsAndRegsApi';
import { commoditiesApi } from '../reducers/commoditiesApi';
import { siteSearchApi } from '../reducers/siteSearchApi';
import { userProfileApi } from '../reducers/userProfileApi';
import { eventsApi } from '../reducers/eventsApi';

const lawsAndRegsPageService = new LawsAndRegsPageService();

export const setupStore = (preloadedState?: Partial<RootState>) => {
    return configureStore({
        reducer: globalReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
                thunk: {
                    extraArgument: {
                        lawsAndRegsApi: lawsAndRegsPageService,
                    },
                },
            })
                .concat(hierarchyApi.middleware)
                .concat(marketsApi.middleware)
                .concat(mvLawsAndRegsApi.middleware)
                .concat(commoditiesApi.middleware)
                .concat(siteSearchApi.middleware)
                .concat(userProfileApi.middleware)
                .concat(eventsApi.middleware),
        preloadedState: preloadedState,
    });
};

export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export type RootState = ReturnType<typeof globalReducer>;
