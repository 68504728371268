import React from 'react';
import './NewsOrEventPage.scss';
import ComponentResolver from '../../ComponentResolver';
import { IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import { ContentItem } from '@exporter-services/common-ui';
import { Col, Row } from 'reactstrap';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { setCurrentAceEventId } from '../../providers/reducers/eventSlice';

interface NewsArticlePageProps {
    data: {
        item: ContentItem;
        linkedItems: IContentItemsContainer;
        mainContentItemNames: Array<string>;
        rightHandContentItemNames?: Array<string>;
    };
    match: any;
}

export default function NewsOrEventPage(props: NewsArticlePageProps) {
    const {
        data: { item, linkedItems, mainContentItemNames, rightHandContentItemNames },
        match,
    } = props;
    const dispatch = useAppDispatch();
    dispatch(setCurrentAceEventId(item?.elements?.ace_event_id?.value));

    const mainContentItems = mainContentItemNames?.map((el) => linkedItems?.[el]) || [];
    const rightContentItems = rightHandContentItemNames?.map((el) => linkedItems?.[el]) || [];
    const showRightContent = rightContentItems.length > 0;
    const lgWidth = showRightContent ? 8 : 9;

    const renderComponent = (contentItems: ContentItem[]) =>
        contentItems?.map((item: ContentItem) => <ComponentResolver data={{ item: item, linkedItems }} key={item.system.id} match={match} />);

    return (
        <Row className="news-or-events-page-container">
            <Col xs={12} md={11} lg={lgWidth} xl={8}>
                {renderComponent(mainContentItems)}
            </Col>
            {showRightContent && (
                <Col md={11} lg={4}>
                    <div className="sidebar-content">{renderComponent(rightContentItems)}</div>
                </Col>
            )}
        </Row>
    );
}
