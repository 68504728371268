import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { MvDocumentCategoryKey } from '../../models/enums';
import { MvImportDocumentData, MvImportDocumentsData } from '../../models/MVDocumentName';

export const mvLawsAndRegsApi = createApi({
    baseQuery: fetchBaseQuery({ baseUrl: '/api/mv/' }),
    reducerPath: 'mvLawsAndRegsApi',
    endpoints: (build) => ({
        getMvImportDocumentsList: build.query<MvImportDocumentsData, { sector: string; market: string; type: MvDocumentCategoryKey }>({
            query: ({ sector, market, type }) => ({
                url: `countries/${market.toUpperCase()}/products/${sector}/importdocuments/list/`,
                params: { categories: `${type}` },
            }),
            transformResponse: (response: MvImportDocumentsData) => {
                if (response) {
                    response.LastUpdated = new Date(response.LastUpdated);
                }
                return response;
            },
        }),

        getMvImportDocumentData: build.query<MvImportDocumentData, { sector: string; market: string; documentId: string }>({
            query: ({ sector, market, documentId }) => `countries/${market.toUpperCase()}/products/${sector}/importdocument/${documentId}`,
            transformResponse: (response: MvImportDocumentData) => {
                if (response) {
                    response.LastUpdated = new Date(response.LastUpdated);
                }
                return response;
            },
        }),
    }),
});

export const { useGetMvImportDocumentsListQuery, useGetMvImportDocumentDataQuery } = mvLawsAndRegsApi;

export default mvLawsAndRegsApi.reducer;
