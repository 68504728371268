import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';
import dayjs from 'dayjs';
import { ContentItem } from '@exporter-services/common-ui';
import './EventResultsContainer.scss';
import { client } from '../../config';
import { EventTilePathwayItem } from '../eventTilePathway/EventTilePathwayItem';
import EventTilePathway from '../eventTilePathway/EventTilePathway';
import { EventTag } from '../../models/enums';
import { useGetRegistrationsQuery } from '../../providers/reducers/eventsApi';
import LoadingOverlay from 'react-loading-overlay-ts';
import SyncLoader from 'react-spinners/SyncLoader';

interface EventResultsContainerProps {
    data: {
        item: ContentItem;
    };
}

const EventResultsContainer = (props: EventResultsContainerProps) => {
    const [loading, setLoading] = useState(true);
    const videoRecordingLabel = EventTag.Recording;
    const { isAuthenticated } = useAuth0();
    const [eventPageContentItems, setEventPageContentItems] = useState<EventTilePathwayItem[]>(null);

    const filterByCurrentUserRegisteredEvents = useMemo(() => {
        const additionalFilters = props.data.item?.elements.additional_filters?.value;
        return additionalFilters?.some((filter: any) => filter.codename === 'current_user_registered');
    }, []);

    const { data: eventRegistrations, isLoading: eventRegistrationsLoading } = useGetRegistrationsQuery(
        {},
        { skip: !isAuthenticated || !filterByCurrentUserRegisteredEvents },
    );

    useEffect(() => {
        if (eventRegistrationsLoading) return;

        const eventType = props.data.item?.elements.event_type?.value?.[0]?.codename;
        const registeredEventIds = eventRegistrations?.filter((r) => r.Status.toLowerCase() === 'registered').map((r) => r.EventId);

        let query;

        if (eventType && filterByCurrentUserRegisteredEvents) {
            if (registeredEventIds?.length > 0) {
                query = client
                    .items()
                    .equalsFilter('system.type', 'event_page')
                    .containsFilter('elements.event_type', [eventType])
                    .inFilter('elements.ace_event_id', registeredEventIds)
                    .depthParameter(2);
            }
        } else if (eventType) {
            query = client.items().equalsFilter('system.type', 'event_page').containsFilter('elements.event_type', [eventType]).depthParameter(2);
        } else if (filterByCurrentUserRegisteredEvents && registeredEventIds?.length > 0) {
            query = client.items().equalsFilter('system.type', 'event_page').inFilter('elements.ace_event_id', registeredEventIds).depthParameter(2);
        }

        if (query) {
            query.toPromise().then((response) => {
                setEventPageContentItems([
                    ...response.data.items
                        .filter((item) => item.elements.label?.value?.toLowerCase() !== videoRecordingLabel)
                        .sort(sortEventsByStartDate),
                    ...response.data.items
                        .filter((item) => item.elements.label?.value?.toLowerCase() === videoRecordingLabel)
                        .sort(sortEventsByStartDate),
                ]);
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, [eventRegistrationsLoading]);

    const sortEventsByStartDate = (next: ContentItem, prev: ContentItem) => {
        let nextEventDetails = next.elements.content['linkedItems'].find((i: ContentItem) => i.system.type === 'event_details');
        if (!nextEventDetails) {
            nextEventDetails = next.elements.right_hand_content['linkedItems'].find((i: ContentItem) => i.system.type === 'event_details');
        }

        let prevEventDetails = prev.elements.content['linkedItems'].find((i: ContentItem) => i.system.type === 'event_details');
        if (!prevEventDetails) {
            prevEventDetails = prev.elements.right_hand_content['linkedItems'].find((i: ContentItem) => i.system.type === 'event_details');
        }

        return dayjs(nextEventDetails?.elements.start_date?.value).diff(prevEventDetails?.elements.start_date?.value);
    };

    return (
        <LoadingOverlay active={loading} spinner={<SyncLoader />} text="Please wait" className="loader">
            <div className="event-results-container">
                <Row>
                    <Col>
                        {eventPageContentItems?.length > 0 ? (
                            <h2 className="results-count-header">
                                Showing <strong>{eventPageContentItems.length}</strong> {eventPageContentItems.length === 1 ? 'event' : 'events'}
                            </h2>
                        ) : (
                            <h2 className="results-count-header">No events to show</h2>
                        )}
                    </Col>
                </Row>
                <Row className="event-results-container-items full-width">
                    {eventPageContentItems?.length > 0 &&
                        eventPageContentItems.map((item: EventTilePathwayItem) => (
                            <Col lg={4} sm={6} className="list-block" key={item.system.id}>
                                <EventTilePathway data={{ content: item }} client={client} key={item.system.id} />
                            </Col>
                        ))}
                </Row>
            </div>
        </LoadingOverlay>
    );
};

export default EventResultsContainer;
