import React, { useLayoutEffect, useRef, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { ComButton, ContentItem } from '@exporter-services/common-ui';
import './Authentication.scss';
import HeadingLevelResolver from '../headingLevelResolver/HeadingLevelResolver';
import { getSubHeadingLevel, isHtmlEmpty } from '../../utils/utils';
import { PageService } from '../../services/PageService';

interface AuthenticationProps {
    data: {
        item: ContentItem;
        isModal?: boolean;
    };
}

const Authentication = (props: AuthenticationProps) => {
    const authContainerRef = useRef<HTMLDivElement>(null);
    const { loginWithRedirect } = useAuth0();
    const [isFullWidth, setIsFullWidth] = useState(false);

    const {
        item: {
            elements: {
                title,
                sign_in_title: signInTitle,
                sign_in_description: signInDescription,
                sign_in_button_text: signInButtonText,
                sign_up_title: signUpTitle,
                sign_up_description: signUpDescription,
                sign_up_button_text: signUpButtonText,
                headinglevelsnippet__level: headingLevel,
            },
        },
        isModal,
    } = props.data;

    useLayoutEffect(() => {
        const handleResize = () => {
            if (authContainerRef.current?.parentElement?.clientWidth > PageService.SMALL_BREAKPOINT) setIsFullWidth(false);
            else setIsFullWidth(true);
        };

        if (isModal) setIsFullWidth(true);
        else {
            window.addEventListener('resize', handleResize);
            handleResize();
        }

        return () => window.removeEventListener('resize', handleResize);
    }, [authContainerRef.current]);

    return (
        <div ref={authContainerRef} className={isModal ? 'auth-container-modal' : 'auth-container-non-modal'}>
            {title?.value && (
                <HeadingLevelResolver
                    data={{
                        headingLevel,
                        titleText: title?.value,
                    }}
                />
            )}
            <Row>
                <Col className={isFullWidth ? 'mb-6 col-12' : 'mb-4 col-6'}>
                    <HeadingLevelResolver
                        data={{
                            headingLevel: getSubHeadingLevel(headingLevel),
                            titleText: signInTitle.value,
                        }}
                    />
                    {!isHtmlEmpty(signInDescription.value) && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: signInDescription.value,
                            }}
                        ></div>
                    )}
                    <ComButton onClick={() => loginWithRedirect()}>{signInButtonText.value}</ComButton>
                </Col>
                <Col className={`mb-4 ${isFullWidth ? 'col-12' : 'col-6'}`}>
                    <HeadingLevelResolver
                        data={{
                            headingLevel: getSubHeadingLevel(headingLevel),
                            titleText: signUpTitle.value,
                        }}
                    />
                    {!isHtmlEmpty(signUpDescription.value) && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: signUpDescription.value,
                            }}
                        ></div>
                    )}
                    <ComButton
                        variant="secondary"
                        onClick={() =>
                            loginWithRedirect({
                                authorizationParams: {
                                    screen_hint: 'signup',
                                },
                            })
                        }
                    >
                        {signUpButtonText.value}
                    </ComButton>
                </Col>
            </Row>
        </div>
    );
};

export default Authentication;
