import { CurrentEventPageUrlInfo } from '../../models/ReduxModels';
import GlobalState, { EventState } from '../globalState/globalState';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initState: EventState = {
    currentEventPageUrlInfo: { retrieved: false, pending: false, data: undefined },
    currentAceEventId: undefined,
};

const eventSlice = createSlice({
    name: 'event',
    initialState: initState,
    reducers: {
        pendingGetCurrentEventPageUrlInfo(state) {
            state.currentEventPageUrlInfo.pending = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCurrentEventPageUrlByAceEventId.fulfilled, (state, action) => {
            state.currentEventPageUrlInfo = action.payload;
        });
        builder.addCase(setCurrentAceEventId.fulfilled, (state, action) => {
            state.currentAceEventId = action.payload;
        });
    },
});

export const setCurrentAceEventId = createAsyncThunk('event/setCurrentAceEventId', async (request: string, thunkAPI: any) => {
    return await new Promise<string>((resolve) => {
        resolve(request);
    });
});

export const getCurrentEventPageUrlByAceEventId = createAsyncThunk(
    'event/getCurrentEventPageUrlByAceEventId',
    async (request: string, thunkAPI: any) => {
        const state = thunkAPI.getState() as GlobalState;
        if (state.event.currentEventPageUrlInfo.retrieved || state.event.currentEventPageUrlInfo.pending) {
            return thunkAPI.rejectWithValue(true);
        }
        thunkAPI.dispatch(eventSlice.actions.pendingGetCurrentEventPageUrlInfo());

        return await new Promise<CurrentEventPageUrlInfo>((resolve) => {
            state.singletonServices.eventService
                .getEventPageUrlByAceEventId(request)
                .then((resp) => {
                    resolve({ retrieved: true, pending: false, data: resp });
                })
                .catch(() => {
                    resolve({ retrieved: true, pending: false });
                });
        });
    },
);

const eventReducer = eventSlice.reducer;

export default eventReducer;
