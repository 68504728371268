import { BootstrapBreakpoint } from '../models/enums';

export class PageService {
    static readonly SMALL_BREAKPOINT: number = 576;
    static readonly MEDIUM_BREAKPOINT: number = 768;
    static readonly LARGE_BREAKPOINT: number = 992;
    static readonly EXTRA_LARGE_BREAKPOINT: number = 1200;
    static readonly EXTRA_EXTRA_LARGE_BREAKPOINT: number = 1400;

    public GetCurrentBootstrapBreakpoint = (pageWidth: number): BootstrapBreakpoint => {
        if (isNaN(pageWidth) || pageWidth === null) {
            return undefined;
        }

        if (pageWidth < PageService.SMALL_BREAKPOINT) {
            return BootstrapBreakpoint.ExtraSmall;
        } else if (pageWidth >= PageService.SMALL_BREAKPOINT && pageWidth < PageService.MEDIUM_BREAKPOINT) {
            return BootstrapBreakpoint.Small;
        } else if (pageWidth >= PageService.MEDIUM_BREAKPOINT && pageWidth < PageService.LARGE_BREAKPOINT) {
            return BootstrapBreakpoint.Medium;
        } else if (pageWidth >= PageService.LARGE_BREAKPOINT && pageWidth < PageService.EXTRA_LARGE_BREAKPOINT) {
            return BootstrapBreakpoint.Large;
        } else if (pageWidth >= PageService.EXTRA_LARGE_BREAKPOINT && pageWidth < PageService.EXTRA_EXTRA_LARGE_BREAKPOINT) {
            return BootstrapBreakpoint.ExtraLarge;
        }

        return BootstrapBreakpoint.ExtraExtraLarge;
    };

    public GetCurrentApplicableBootstrapBreakpointList = (pageWidth: number): Array<BootstrapBreakpoint> => {
        if (isNaN(pageWidth) || pageWidth === null) {
            return undefined;
        }

        let response = [];
        if (pageWidth < PageService.SMALL_BREAKPOINT) {
            response.push(BootstrapBreakpoint.ExtraSmall);
        }
        if (pageWidth >= PageService.SMALL_BREAKPOINT) {
            response.push(BootstrapBreakpoint.Small);
        }
        if (pageWidth >= PageService.MEDIUM_BREAKPOINT) {
            response.push(BootstrapBreakpoint.Medium);
        }
        if (pageWidth >= PageService.LARGE_BREAKPOINT) {
            response.push(BootstrapBreakpoint.Large);
        }
        if (pageWidth >= PageService.EXTRA_LARGE_BREAKPOINT) {
            response.push(BootstrapBreakpoint.ExtraLarge);
        }
        if (pageWidth >= PageService.EXTRA_EXTRA_LARGE_BREAKPOINT) {
            response.push(BootstrapBreakpoint.ExtraExtraLarge);
        }

        return response;
    };

    public IsCurrentBootstrapBreakpointOrGreater = (currentApplicableBreakpoints: BootstrapBreakpoint[], breakpointToCheck: BootstrapBreakpoint) => {
        if (breakpointToCheck === BootstrapBreakpoint.ExtraSmall) {
            return true;
        } else {
            if (currentApplicableBreakpoints.find((b) => b === breakpointToCheck)) {
                return true;
            }
        }

        return false;
    };

    public IsCurrentBootstrapBreakpointOnly = (currentApplicableBreakpoints: BootstrapBreakpoint[], breakpointToCheck: BootstrapBreakpoint) => {
        if (breakpointToCheck === BootstrapBreakpoint.ExtraSmall) {
            if (
                currentApplicableBreakpoints.length === 1 &&
                currentApplicableBreakpoints.filter((b) => b === BootstrapBreakpoint.ExtraSmall).length === 1
            ) {
                return true;
            }
        } else {
            if (
                this.IsCurrentBootstrapBreakpointOrGreater(currentApplicableBreakpoints, breakpointToCheck) &&
                currentApplicableBreakpoints.filter((b) => b > breakpointToCheck).length === 0
            ) {
                return true;
            }
        }

        return false;
    };
}
