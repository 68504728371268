import React from 'react';
import ArrowLeft from '../../assets/breadcrumbMobileArrowLeft.svg';
import ArrowRight from '../../assets/breadcrumbRightArrowDesktop.svg';
import './Breadcrumb.scss';
import { useAppSelector } from '../../hooks/reduxHooks';
import { PageService } from '../../services/PageService';
import { BootstrapBreakpoint } from '../../models/enums';
import { Col, Container, Row } from 'reactstrap';

export interface BreadcrumbParam {
    title: string;
    url: string;
}

export interface BreadcrumbProps {
    id?: string;
    menus: BreadcrumbParam[];
    className?: string;
    basepath?: string;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const Breadcrumb = (props: BreadcrumbProps) => {
    const secondLastMenuItem = props.menus[props.menus.length - 2];
    const { menus, basepath } = props;
    const applicableBootstrapBreakpoints = useAppSelector<BootstrapBreakpoint[]>((state) => state.page.applicableBootstrapBreakpoints);
    const pageService = useAppSelector<PageService>((state) => state.singletonServices.pageService);
    const TAB_DISABLED_INDEX = -1;

    const isSmallViewportOrGreater = (breakpoints: BootstrapBreakpoint[]) => {
        return pageService.IsCurrentBootstrapBreakpointOrGreater(breakpoints, BootstrapBreakpoint.Small);
    };

    return (
        <div data-testid="breadcrumb" className="breadcrumb-holder">
            <Container>
                <Row>
                    <Col md={12}>
                        <ul
                            role={'navigation'}
                            aria-hidden={!isSmallViewportOrGreater(applicableBootstrapBreakpoints)}
                            className="breadcrumb-home-wrapper d-none d-sm-block"
                        >
                            {menus.map((r, index) =>
                                r.url && secondLastMenuItem ? (
                                    <li key={index}>
                                        <a
                                            href={basepath ? `${basepath}${r.url}` : r.url}
                                            className="breadcrumb-link"
                                            tabIndex={!isSmallViewportOrGreater(applicableBootstrapBreakpoints) ? TAB_DISABLED_INDEX : 0}
                                        >
                                            {r.title}
                                            <img src={ArrowRight} alt="" className="ms-2" />
                                        </a>{' '}
                                    </li>
                                ) : (
                                    <li key={index}>
                                        <span className="breadcrumb-location">{r.title}</span>
                                    </li>
                                ),
                            )}
                        </ul>
                        <div
                            role="presentation"
                            aria-hidden={isSmallViewportOrGreater(applicableBootstrapBreakpoints)}
                            className="breadcrumb-home-wrapper d-block d-sm-none"
                        >
                            <a
                                href={basepath ? basepath + secondLastMenuItem.url : secondLastMenuItem.url}
                                className="breadcrumb-link"
                                tabIndex={isSmallViewportOrGreater(applicableBootstrapBreakpoints) ? TAB_DISABLED_INDEX : 0}
                            >
                                <img src={ArrowLeft} alt="" className="me-2 left-arrow" />
                                {secondLastMenuItem.title}
                            </a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Breadcrumb;
