import React, { useEffect, useContext } from 'react';
import Content from '../page/layouts/Content';
import SectorLandingPage from '../page/layouts/SectorLandingPage';
import { IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import { PageItem } from '../models/PageItem';
import { personalisationContext } from '../providers/personalisation';
import { setIsPersonalised } from '../providers/personalisation/reducer';
import NewsOrEventPage from './layouts/NewsOrEventPage';

interface LayoutResolverProps {
    data: {
        item: PageItem;
        linkedItems: IContentItemsContainer;
    };
    match: any;
}

const LayoutResolver = (props: LayoutResolverProps) => {
    const { dispatch } = useContext(personalisationContext);

    useEffect(() => {
        dispatch(setIsPersonalised(props.data.item.elements.personalisation?.value?.length > 0));
    }, []);

    const renderComponent = () => {
        switch (props.data.item.system.type) {
            case 'page':
                return (
                    <Content
                        data={{
                            item: props.data.item,
                            linkedItems: props.data.linkedItems,
                        }}
                        match={props.match}
                    />
                );
            case 'sector_landing_page':
                return (
                    <SectorLandingPage
                        data={{
                            itemNames: props.data.item.elements.content?.value,
                            linkedItems: props.data.linkedItems,
                        }}
                        match={props.match}
                    />
                );
            case 'news_article_page':
            case 'case_study_page':
            case 'blog_article_page':
            case 'event_page':
                return (
                    <NewsOrEventPage
                        data={{
                            item: props.data.item,
                            linkedItems: props.data.linkedItems,
                            mainContentItemNames: props.data.item.elements.content?.value,
                            rightHandContentItemNames: props.data.item.elements.right_hand_content?.value,
                        }}
                        match={props.match}
                    />
                );
            default:
                return '';
        }
    };
    return <div>{renderComponent()}</div>;
};
export default LayoutResolver;
