import { getParentPath } from '@exporter-services/common-ui';
import { client } from '../config';

export class EventService {
    public getEventPageUrlByAceEventId = (aceEventId: string): Promise<string> =>
        new Promise<string>((resolve, reject) => {
            client
                .items()
                .equalsFilter('elements.ace_event_id', aceEventId)
                .notEqualsFilter('system.workflow_step', 'archived')
                .depthParameter(5)
                .toPromise()
                .then((response) => {
                    const codename = response.data.items?.[0]?.system?.codename;
                    const eventPageUrl = response.data.items?.[0]?.elements?.url?.value;

                    if (codename && eventPageUrl) getParentPath(client, codename, eventPageUrl, resolve);
                    else resolve('not-found');
                })
                .catch((err) => reject(err));
        });
}
