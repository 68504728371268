import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { clearUserProfile } from '../../utils/authUtils';

export const Logout = () => {
    const { isAuthenticated, isLoading, logout } = useAuth0();
    const navigate = useNavigate();
    const [query] = useSearchParams();

    useEffect(() => {
        if (!isLoading) {
            const returnTo = query.get('returnTo');

            if (isAuthenticated) {
                clearUserProfile();
                logout({ logoutParams: { returnTo: returnTo ? window.location.origin + returnTo : window.location.origin } });
            } else {
                navigate(returnTo ?? '/');
            }
        }
    }, [isLoading]);

    return <></>;
};
