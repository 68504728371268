import { ITaxonomyGroup } from '@kontent-ai/delivery-sdk';
import { client } from '../config';
import HttpServiceBase from '../services/HttpServiceBase';

// NOTE: This service and its base class is legacy code and shouldn't be used for future work, use TaxonomySlice/TaxonomyService instead
export default class DeliveryService extends HttpServiceBase {
    public getTaxonomyGroup(taxonomyGroupCode: string): Promise<ITaxonomyGroup> {
        return client
            .taxonomy(taxonomyGroupCode)
            .toPromise()
            .then((response) => response.data.taxonomy)
            .catch((error) => this.handleError(error));
    }
}
