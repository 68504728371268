import { useEffect, useState, useMemo } from 'react';
import { BootstrapBreakpoint } from '../models/enums';
import { PageService } from '../services/PageService';
import { useAppSelector } from './reduxHooks';

export const useGetDisplayType = () => {
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [isTablet, setIsTablet] = useState<boolean>(false);
    const [isDesktop, setIsDesktop] = useState<boolean>(false);
    const pageService = useAppSelector<PageService>((state) => state.singletonServices.pageService);
    const [breakpoints, setBreakpoints] = useState<BootstrapBreakpoint[]>([]);
    const applicableBootstrapBreakpoints = useAppSelector<BootstrapBreakpoint[]>((state) => state.page.applicableBootstrapBreakpoints);

    const memoBreakpoints = useMemo(() => {
        if (JSON.stringify(applicableBootstrapBreakpoints) !== JSON.stringify(breakpoints)) {
            setBreakpoints(applicableBootstrapBreakpoints);
        }
        return breakpoints;
    }, [breakpoints, applicableBootstrapBreakpoints]);

    useEffect(() => {
        const isMobileViewport = (breakpoints: BootstrapBreakpoint[]) =>
            pageService.IsCurrentBootstrapBreakpointOnly(breakpoints, BootstrapBreakpoint.ExtraSmall);

        const isTabletViewport = (breakpoints: BootstrapBreakpoint[]) =>
            pageService.IsCurrentBootstrapBreakpointOnly(breakpoints, BootstrapBreakpoint.Small) ||
            pageService.IsCurrentBootstrapBreakpointOnly(breakpoints, BootstrapBreakpoint.Medium);

        const isLargeViewportOrGreater = (breakpoints: BootstrapBreakpoint[]) =>
            pageService.IsCurrentBootstrapBreakpointOrGreater(breakpoints, BootstrapBreakpoint.Large);

        if (!isMobile && isMobileViewport(memoBreakpoints)) {
            setIsMobile(true);
            setIsTablet(false);
            setIsDesktop(false);
        } else if (!isTablet && isTabletViewport(memoBreakpoints)) {
            setIsMobile(false);
            setIsTablet(true);
            setIsDesktop(false);
        } else if ((isMobile || isTablet) && isLargeViewportOrGreater(memoBreakpoints)) {
            setIsMobile(false);
            setIsTablet(false);
            setIsDesktop(true);
        }
    }, [JSON.stringify(memoBreakpoints)]);

    return { isMobile, isTablet, isDesktop };
};
