import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import { ContentItem } from '@exporter-services/common-ui';
import { client } from '../../config';
import LoadingOverlay from 'react-loading-overlay-ts';
import SyncLoader from 'react-spinners/SyncLoader';
import FocusTrap from 'focus-trap-react';
import Authentication from './Authentication';
import '../../styles/_modalDialog.scss';

interface AuthenticationModalProps {
    onClose: () => void;
}

const AuthenticationModal = (props: AuthenticationModalProps) => {
    const [content, setContent] = useState<ContentItem>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        client
            .items()
            .equalsFilter('system.type', 'authentication_call_to_action')
            .equalsFilter('system.codename', 'modal_auth_call_to_action')
            .depthParameter(0)
            .toPromise()
            .then((response) => {
                setContent(response.data.items[0]);
                setLoading(false);
            });
    }, []);

    const handleKeyDown = (event: BaseSyntheticEvent | KeyboardEvent) => {
        if ((event as KeyboardEvent).key === 'Escape') props.onClose();
    };

    return (
        <>
            <div className="modal-overlay" />
            <div className="modal-wrapper" aria-modal={true} tabIndex={-1} role="dialog" onKeyDown={handleKeyDown}>
                <div className="modal-narrow">
                    <div className="modal-close close-icon" onMouseDown={props.onClose}></div>

                    <LoadingOverlay active={loading} spinner={<SyncLoader />} text="Please wait..." className="loader">
                        {content && (
                            <FocusTrap>
                                <div>
                                    <Authentication data={{ item: content, isModal: true }} />
                                </div>
                            </FocusTrap>
                        )}
                    </LoadingOverlay>
                </div>
            </div>
        </>
    );
};

export default AuthenticationModal;
