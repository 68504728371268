import { ComButtonVariantType, ContentItem } from '@exporter-services/common-ui';

export enum EventStatus {
    BookingsOpen,
    BookingConfirmed,
    BookingsClosed,
    JoinNow,
    Closed,
}

export interface EventDetailsProps {
    data: {
        item: ContentItem;
    };
}

interface ButtonProps {
    text: string;
    signinText?: string;
    variant: ComButtonVariantType;
}

export interface EventStateProps {
    title: string;
    description?: string;
    noSpotsLeftDescription?: string;
    wrapperClass?: string;
    iconClass: string;
    button?: ButtonProps;
}

export const EventStateMap: Record<EventStatus, EventStateProps> = {
    [EventStatus.BookingsOpen]: {
        title: 'Book now',
        description: 'Register for this event.',
        iconClass: 'event-icon',
        button: {
            text: 'Book',
            signinText: 'Sign in to book',
            variant: 'primary',
        },
    },
    [EventStatus.BookingConfirmed]: {
        title: 'Booking confirmed',
        description: "We've sent you a confirmation email with details.",
        wrapperClass: 'booking-confirmed',
        iconClass: 'success-tick-icon',
        button: {
            text: 'Cancel',
            variant: 'secondary',
        },
    },
    [EventStatus.BookingsClosed]: {
        title: 'Bookings closed',
        noSpotsLeftDescription: 'Sorry. No spots left.',
        wrapperClass: 'booking-closed',
        iconClass: 'booking-closed-icon',
    },
    [EventStatus.JoinNow]: {
        title: 'Join now',
        wrapperClass: 'join-now',
        iconClass: 'event-available-icon',
        button: {
            text: 'Join event',
            variant: 'primary',
        },
    },
    [EventStatus.Closed]: {
        title: 'Event closed',
        wrapperClass: 'event-completed',
        iconClass: 'event-completed-icon',
    },
};
