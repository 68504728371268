import React, { useCallback, useEffect, useState } from 'react';
import { Element, DOMNode } from 'html-react-parser';
import { ElementType } from 'domelementtype';

import { HscodeHierarchy, HscodeHierarchyData } from '../../../models/HscodeHierarchy';
import { LawsAndRegsDataSourceType } from '../../../models/enums';
import { ReplaceEmbeddedObjectsFunction, ReplaceMobileEmbeddedObjectsFunction } from '../../../models/types/ReplaceEmbeddedObjectsFunction';
import ExpandCollapseTree, { isListItemOnLeafLevel } from '../../../controls/tree/ExpandCollapseTree';
import './NarrowDownSearchTree.scss';
import LawsAndRegsTreeBase from '../lawsAndRegsTreeBase/LawsAndRegsTreeBase';
import { ExpandCollapseTreeRecord, ExpandCollapseTreeUtils, ListItemStateHashList } from '../../../controls/tree/ExpandCollapseTreeUtils';
import { TreeNodeListItemHtmlModifiers } from '../../../controls/tree/TreeUtils';
import { ComButton } from '@exporter-services/common-ui';

interface NarrowDownSearchTreeProps {
    data: {
        dataSourceType?: LawsAndRegsDataSourceType;
        hierarchy?: HscodeHierarchy[];
        sector: string;
        market: string;
    };
    onNarrowDownSearchItemSelected: (selectedSector: string) => void;
    onLoadChange: (loading: boolean) => void;
    onShowChange: (show: boolean) => void;
}

class NarrowDownSearchTreeHierarchy implements ExpandCollapseTreeRecord<HscodeHierarchyData> {
    Children: ExpandCollapseTreeRecord<HscodeHierarchyData>[];
    Data: HscodeHierarchyData;
    Id: string;
    IsExpanded: boolean;
    IsLeafLevel: boolean;
    Selected: boolean;
}

const ITEM_FOCUSED_CLASS = 'is-focused';

const popLastIdStub = (id: string) => {
    if (id && id.indexOf('.') > -1) {
        const splits = id.split('.');
        return splits.slice(0, splits.length - 1).join('.');
    }

    return null;
};

const isListItemAncestor = (parentId: string, listItem: { id: string; isExpanded: boolean }) => {
    if (listItem) {
        if (!parentId) {
            return true;
        }

        if (parentId.startsWith(listItem.id)) {
            return true;
        }

        let temp = listItem.id;
        while (temp) {
            if (parentId.startsWith(temp)) {
                return true;
            }
            temp = popLastIdStub(temp);
        }
    }

    return false;
};

const assignDetailsToNode = (
    node: NarrowDownSearchTreeHierarchy | HscodeHierarchy,
    parentId: string,
    latestToggleItem: { id: string; isExpanded: boolean },
): NarrowDownSearchTreeHierarchy => {
    const id = `${parentId ? `${parentId}.` : ''}${node.Id}`;

    const children =
        node.Children &&
        node.Children.length > 0 &&
        node.Children.map((c) => assignDetailsToNode(c as NarrowDownSearchTreeHierarchy, id, latestToggleItem));

    const isNodeAncestor = isListItemAncestor(parentId, latestToggleItem);
    const isNodeRoot = id.indexOf('.') === -1;
    return { ...node, Id: id, Children: children, Selected: node.Selected, IsExpanded: isNodeRoot || isNodeAncestor };
};

const isListItemFocused = (listItemId: string, latestToggleItem: { id: string; isExpanded: boolean }) => {
    if (!latestToggleItem) {
        return ExpandCollapseTreeUtils.isListItemRoot(listItemId);
    } else if (ExpandCollapseTreeUtils.isListItemRoot(latestToggleItem.id) && ExpandCollapseTreeUtils.isListItemRoot(listItemId)) {
        return true;
    } else if (latestToggleItem.id === listItemId && latestToggleItem.isExpanded) {
        return true;
    } else if (!latestToggleItem.isExpanded && listItemId === ExpandCollapseTreeUtils.getParentListItemId(latestToggleItem.id)) {
        return true;
    }

    return false;
};

const calculateListItemHtmlModifiers = (
    tr: HscodeHierarchy,
    root: HscodeHierarchy,
    isRoot: boolean,
    depth: number,
    index: number,
    listItemStateHashList: ListItemStateHashList,
    isExpanded: boolean,
    isLeafLevel: boolean,
    searchQueryHash?: string,
    latestToggleItem?: { id: string; isExpanded: boolean },
): TreeNodeListItemHtmlModifiers => {
    let classesString = '';

    let dataAttributes = {};

    if (isListItemFocused(tr.Id, latestToggleItem)) {
        classesString += ` ${ITEM_FOCUSED_CLASS}`;
    }

    if (isLeafLevel) {
        classesString += ' is-leaf-level';
    }

    if (isRoot) {
        dataAttributes['data-searchqueryhash'] = searchQueryHash;
    }

    if (tr.Children && tr.Children.length > 0) {
        classesString += ' has-children';

        if (isExpanded) {
            classesString += ' is-expanded';
        }
    }

    return { classString: classesString, dataAttributeCollection: dataAttributes };
};

const NarrowDownSearchTree = (props: NarrowDownSearchTreeProps) => {
    const [treeHtml, setTreeHtml] = useState<string>('');
    const [latestToggleItem, setLatestToggleItem] = useState<{ id: string; isExpanded: boolean }>();
    const [hierarchy, setHierarchy] = useState<NarrowDownSearchTreeHierarchy[]>();
    const [listItemState, setListItemState] = useState<ListItemStateHashList>();
    const [hierarchyChangeCount, setHierarchyChangeCount] = useState<number>(0);
    const [searchQueryHash, setSearchQueryHash] = useState<string>();

    //#region lifecycle
    const showComponent = useCallback(() => {
        const generated = ExpandCollapseTreeUtils.generateTreeHtml(
            hierarchy,
            listItemState,
            hierarchy[0],
            `${props.data.market}_${props.data.sector}`,
            (treeRecord, rootNode, isRoot, depth, index, listItemStateHashList, isExpanded, isLeafLevel, searchQueryHash) => {
                return calculateListItemHtmlModifiers(
                    treeRecord,
                    rootNode,
                    isRoot,
                    depth,
                    index,
                    listItemStateHashList,
                    isExpanded,
                    isLeafLevel,
                    searchQueryHash,
                    latestToggleItem,
                );
            },
            handleReplaceTreeHtml,
        );

        setTreeHtml(generated);

        props.onShowChange(true);
        props.onLoadChange(false);
    }, [hierarchy, listItemState]);

    useEffect(() => {
        if (hierarchy && hierarchy.length > 0 && listItemState) {
            showComponent();
        }
    }, [showComponent, hierarchy, JSON.stringify(listItemState)]);

    useEffect(() => {
        if (props.data.hierarchy && props.data.hierarchy.length > 0) {
            const root = assignDetailsToNode(props.data.hierarchy[0] as NarrowDownSearchTreeHierarchy, null, null);
            setListItemState(ExpandCollapseTreeUtils.generateInitialListItemState([root]));
        }
    }, [hierarchyChangeCount]);

    useEffect(() => {
        const tempSearchQueryHash = `${props.data.market}_${props.data.sector}`;
        if (searchQueryHash !== tempSearchQueryHash) {
            setLatestToggleItem(undefined);
            setSearchQueryHash(tempSearchQueryHash);
        }
    }, [props.data.sector, props.data.market]);

    useEffect(() => {
        if (props.data.hierarchy && props.data.hierarchy.length > 0) {
            setHierarchyChangeCount((val) => ++val);
        }
    }, [props.data.hierarchy]);
    //#endregion

    //#region showComponent

    const handleReplaceTreeHtml = (
        tr: HscodeHierarchy,
        root: HscodeHierarchy,
        isRoot: boolean,
        depth: number,
        index: number,
        listItemStateHashList: ListItemStateHashList,
        isExpanded: boolean,
        isLeafLevel: boolean,
    ): string => {
        let text: string = `<ComButton variant="link" {1} data-sector="${tr.Data?.Code}" data-market="${props.data.market}">{0}</ComButton>`;

        const label = tr.Data.Label?.replaceAll('"', '');

        let data = text.replace('{0}', label).replace('{1}', isLeafLevel ? ' gtm-leafitem-title' : '');
        let aheccAnchor = text
            .replace('{0}', props.data.dataSourceType === LawsAndRegsDataSourceType.Micor ? tr.Data?.Code.substring(0, 8) : tr.Data?.Code ?? '')
            .replace('{1}', isLeafLevel ? ' gtm-leaftime-code' : '');

        return `<div class="lar-lg"><div class="lar-label" title="${label}">${data}</div><div class="lar-ahecc">${aheccAnchor}</div></div>
                            <div title="${label}" class="lar-md">${data}</div>
                            <div title="${label}" class="lar-sm">${data}</div>`;
    };

    useEffect(() => {
        if (props.data.hierarchy && props.data.hierarchy.length > 0) {
            const root = assignDetailsToNode(props.data.hierarchy[0] as NarrowDownSearchTreeHierarchy, null, latestToggleItem);
            setHierarchy([root]);
        }
    }, [props.data.hierarchy, props.data.market, JSON.stringify(latestToggleItem), props.data.dataSourceType]);

    //#endregion

    //#region page event methods
    const handleNodeOnClick = (e: React.MouseEvent<HTMLButtonElement>, listItemId: string, isExpanded: boolean) => {
        const isLeafLevel = isListItemOnLeafLevel(hierarchy, listItemId);

        if (isLeafLevel && isExpanded) {
            return;
        }

        setLatestToggleItem({ id: listItemId, isExpanded: !isExpanded });

        setListItemState((val) => {
            const keys = Object.keys(val);

            if (!ExpandCollapseTreeUtils.isListItemRoot(listItemId)) {
                val[listItemId] = {
                    isExpanded: !isExpanded,
                    isHidden: ExpandCollapseTreeUtils.isListItemHidden(listItemId, listItemId, !isExpanded),
                };
            }

            for (let i = 0; i < keys.length; ++i) {
                const current = keys[i];
                if (!ExpandCollapseTreeUtils.isListItemRoot(current) && current !== listItemId) {
                    val[current] = {
                        isExpanded: ExpandCollapseTreeUtils.isListItemExpanded(current, listItemId, !isExpanded),
                        isHidden: ExpandCollapseTreeUtils.isListItemHidden(current, listItemId, !isExpanded),
                    };
                }
            }
            return listItemState;
        });

        if (isLeafLevel) {
            props.onNarrowDownSearchItemSelected?.(e.currentTarget.attributes['data-sector'].value);
        } else {
            props.onNarrowDownSearchItemSelected?.(null);
        }
    };

    const handleListItemIconOnClick = (
        e: React.MouseEvent<HTMLDivElement>,
        listItemId: string,
        isExpanded: boolean,
        newListItemState: ListItemStateHashList,
    ) => {
        if (ExpandCollapseTreeUtils.isListItemRoot(listItemId)) {
            isExpanded = true;
        }
        setListItemState(() => newListItemState);
        setLatestToggleItem({ id: listItemId, isExpanded: !isExpanded });
        props.onNarrowDownSearchItemSelected?.(null);
    };

    const handleReplaceEmbeddedObjects = (
        domNode: DOMNode,
        depth: number,
        index: number,
        parentIndex: number,
        listItemId: string,
        isRoot: boolean,
        isExpanded: boolean,
        callback: ReplaceEmbeddedObjectsFunction,
    ) => {
        if (domNode.type === ElementType.Tag) {
            const element = domNode as Element;
            if (element.name.toLowerCase() === 'combutton') {
                return (
                    <ComButton
                        variant="link"
                        data-sector={element.attribs['data-sector']}
                        data-market={element.attribs['data-market']}
                        onClick={(e) => handleNodeOnClick(e, listItemId, isExpanded)}
                    >
                        <>{(domNode as Element).children.map((c) => callback(c as DOMNode, depth, index, parentIndex, listItemId, isRoot))}</>
                    </ComButton>
                );
            }
        }
    };

    const handleReplaceMobileEmbeddedObjects = (
        domNode: DOMNode,
        depth: number,
        index: number,
        parentIndex: number,
        listItemId: string,
        isRoot: boolean,
        isSoleRoot: boolean,
        isExpanded: boolean,
        callback: ReplaceMobileEmbeddedObjectsFunction,
    ) => {
        if (domNode.type === ElementType.Tag) {
            const element = domNode as Element;
            if (element.name.toLowerCase() === 'combutton') {
                return (
                    <ComButton
                        variant="link"
                        data-sector={element.attribs['data-sector']}
                        data-market={element.attribs['data-market']}
                        onClick={(e) => handleNodeOnClick(e, listItemId, isExpanded)}
                    >
                        <>
                            {(domNode as Element).children.map((c) =>
                                callback(c as DOMNode, depth, index, parentIndex, listItemId, isRoot, isSoleRoot),
                            )}
                        </>
                    </ComButton>
                );
            }
        }
    };
    //#endregion

    //#region replace methods
    const replaceRootEmbeddedObject = (domNode: DOMNode) => {
        if (domNode.type === ElementType.Tag) {
            let element = domNode as Element;
            if (element.name === 'ul' && element.parent === null) {
                return (
                    <ExpandCollapseTree
                        HandleSkipLi={(listItemId) => {
                            const isExpanded =
                                latestToggleItem && ExpandCollapseTreeUtils.isListItemRoot(latestToggleItem.id)
                                    ? true
                                    : latestToggleItem
                                      ? latestToggleItem.isExpanded
                                      : true;
                            return latestToggleItem && ExpandCollapseTreeUtils.isListItemHidden(listItemId, latestToggleItem.id, isExpanded);
                        }}
                        HandleSkipList={() => false}
                        SearchQueryHash={`${props.data.market}_${props.data.sector}`}
                        RootTreeElement={element}
                        ListItemStateHashList={listItemState}
                        Hierarchy={hierarchy}
                        HandleListItemIconOnClick={handleListItemIconOnClick}
                        HandleNodeClickEvents={() => null}
                        ReplaceEmbeddedObjects={handleReplaceEmbeddedObjects}
                        ReplaceMobileEmbeddedObjects={handleReplaceMobileEmbeddedObjects}
                    />
                );
            }
        }
    };
    //#endregion

    return (
        <div className="narrow-down-search-tree">
            <LawsAndRegsTreeBase
                data={{
                    treeHtml: treeHtml,
                }}
                replaceRootEmbeddedObject={replaceRootEmbeddedObject}
            />
        </div>
    );
};
export default NarrowDownSearchTree;
