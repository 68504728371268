import { createApi } from '@reduxjs/toolkit/query/react';
import { AbnLookupResult } from '../../models/AbnLookupResult';
import { baseQueryWithAuth, handleErrorResponse } from '../../utils/httpUtils';
import { UserProfile } from '../../models/UserProfile';

export const userProfileApi = createApi({
    reducerPath: 'userProfileApi',
    baseQuery: baseQueryWithAuth('/api/profile'),
    endpoints: (builder) => ({
        getProfile: builder.query<UserProfile, {}>({
            query: () => '/Get',
            transformResponse: (response: UserProfile) => response,
            transformErrorResponse: (error) => handleErrorResponse(error, true),
        }),
        createProfile: builder.mutation<UserProfile, Partial<UserProfile>>({
            query: (body) => ({
                url: '/Create',
                method: 'POST',
                body,
            }),
            transformErrorResponse: (error) => handleErrorResponse(error),
        }),
        updateProfile: builder.mutation<UserProfile, Partial<UserProfile>>({
            query: (body) => ({
                url: '/Update',
                method: 'PUT',
                body,
            }),
            transformErrorResponse: (error) => handleErrorResponse(error),
        }),
        getAbnDetails: builder.query<AbnLookupResult, { abn: string }>({
            query: ({ abn }) => `/AbnLookup?abn=${abn}`,
            transformResponse: (response: AbnLookupResult) => response,
            transformErrorResponse: (error) => handleErrorResponse(error, true),
        }),
    }),
});

export const { useLazyGetAbnDetailsQuery, useCreateProfileMutation, useUpdateProfileMutation, useLazyGetProfileQuery } = userProfileApi;

export default userProfileApi.reducer;
