import React from 'react';
import { Elements } from '@kontent-ai/delivery-sdk';
import { CommonConstants } from '../../CommonConstants';

interface HeadingLevelResolverProps {
    data: {
        headingLevel: Elements.MultipleChoiceElement | string;
        titleText: any;
    };
}

const render = (headingLevelCodeName: string, titleText: any) => {
    if (!headingLevelCodeName) headingLevelCodeName = CommonConstants.DEFAULT_HEADING_LEVEL;

    switch (headingLevelCodeName) {
        case 'h1':
            return <h1>{titleText}</h1>;
        case 'h2':
            return <h2>{titleText}</h2>;
        case 'h3':
            return <h3>{titleText}</h3>;
        case 'h4':
            return <h4>{titleText}</h4>;
        case 'h5':
            return <h5>{titleText}</h5>;
    }
};

const HeadingLevelResolver = (props: HeadingLevelResolverProps) => {
    const {
        data: { headingLevel, titleText },
    } = props;
    const codename = typeof headingLevel === 'string' ? headingLevel : (headingLevel as Elements.MultipleChoiceElement)?.value?.[0]?.codename;
    return render(codename as string, titleText);
};

export default HeadingLevelResolver;
