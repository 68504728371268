import { LawsAndRegsDataSourceType } from '../models/enums';
import { PageName } from '../models/PageName';

export class LawsAndRegsPageService {
    private static isExportRulesFinderPage(): boolean {
        return window.location.href.indexOf(PageName.ExportRulesFinder) > -1;
    }

    public isExportRulesRequirementsPage(lastSlug: string): boolean {
        return this.isExportRulesProductRequirementsPage(lastSlug);
    }

    public isExportRulesProductRequirementsPage(lastSlug: string): boolean {
        return LawsAndRegsPageService.isExportRulesFinderPage() && lastSlug === PageName.ProductRequirements;
    }

    public isExportRulesServiceRequirementsPage(lastSlug: string): boolean {
        return LawsAndRegsPageService.isExportRulesFinderPage() && lastSlug === PageName.ServiceRequirements;
    }

    public isResultsRulesAndRestrictionsDocumentPage(routeMatches: any): boolean {
        if (!routeMatches) {
            return undefined;
        }

        return window.location.href.indexOf(PageName.RulesAndRestrictionsDocument) > -1 && !!routeMatches.params['document'];
    }

    public getLawsAndRegsDataSource = (sector: string) => {
        if (sector.toUpperCase() !== 'ALL') {
            return fetch(`/api/LawsAndRegs/datasource/${sector}`)
                .then((response) => {
                    return response.json();
                })
                .then((response: LawsAndRegsDataSourceType) => {
                    return response;
                });
        }
    };

    public isDutiesAndTaxesPage = (thirdSlug: string): boolean =>
        window.location.pathname.includes(PageName.FindTariffs) && thirdSlug === PageName.Tariffs;
}
