import React, { useMemo, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { ComButton } from '@exporter-services/common-ui';
import LoadingOverlay from 'react-loading-overlay-ts';
import SyncLoader from 'react-spinners/SyncLoader';
import { AbnField } from './AbnField';
import { useUpdateProfileMutation } from '../../providers/reducers/userProfileApi';
import { getUserProfile, setUserProfile } from '../../utils/authUtils';
import { sendUserEvent } from '../../utils/tagManagerUtils';

interface AbnFormProps {
    onSuccess: () => void;
    title?: string;
}

export const AbnForm = ({ onSuccess, title }: AbnFormProps) => {
    const [isAbnValidated, setIsAbnValidated] = useState(false);
    const [isFetchingAbnDetails, setIsFetchingAbnDetails] = useState(false);
    const formContext = useForm({ mode: 'onBlur', reValidateMode: 'onBlur' });
    const {
        handleSubmit,
        formState: { isSubmitting },
    } = formContext;

    const [updateProfile] = useUpdateProfileMutation();

    const saveAbnProvided = async (data: FieldValues) => {
        if (loading) return;

        const userProfile = getUserProfile();
        const response = await updateProfile({
            ...userProfile,
            Abn: data.abn.replaceAll(' ', ''),
        });

        if (!response.error) {
            setUserProfile(response.data);
            sendUserEvent('abn_updated', response.data);

            onSuccess();
        }
    };

    const loading = isFetchingAbnDetails || isSubmitting;

    const loadingText = useMemo(() => {
        if (isSubmitting) return 'Updating your profile. Please wait...';
        if (isFetchingAbnDetails) return 'Fetching ABN details. Please wait...';
        return '';
    }, [isFetchingAbnDetails, isSubmitting]);

    return (
        <LoadingOverlay active={loading} spinner={<SyncLoader />} text={loadingText} className="loader">
            <FormProvider {...formContext}>
                <form className={loading ? 'overflow-hidden' : ''} onSubmit={handleSubmit((data) => saveAbnProvided(data))}>
                    <h2>{title ?? 'Provide your ABN'}</h2>
                    <p>You need to provide your Australian Business Number (ABN) to access webinars. We'll only ask for this once.</p>
                    <AbnField required setIsFetchingAbnDetailsFlag={setIsFetchingAbnDetails} setIsAbnValidatedFlag={setIsAbnValidated} />
                    <div className="button-container">
                        <ComButton type="submit" disabled={isSubmitting}>
                            {isAbnValidated ? 'Save' : 'Check ABN'}
                        </ComButton>
                    </div>
                </form>
            </FormProvider>
        </LoadingOverlay>
    );
};
