import { OnLoadPersonalisationInfo } from '../../models/ReduxModels';
import { OnLoadPersonalisationType } from '../../models/enums';
import { PersonalisationStateInterface } from '../../providers/personalisation/types';

export type QuestionAnswer = { i: number; answer: string };
export type LocalStorageQuestionAnswer = { id: string; answer: string };

export const parseQuestionAnswers = (onLoadPersonalisation: OnLoadPersonalisationInfo): QuestionAnswer[] => {
    const url = new URL(onLoadPersonalisation.url);
    if (!url.search) {
        return null;
    }
    const matchObject = url.search.matchAll(/q\d{1,2}=(yes|notsure|no)/gi);
    return Array.from(matchObject, (m, i) => ({ i: i + 1, answer: m[1] }) as QuestionAnswer);
};

export const getAnswerFromQuerystring = (onLoadPersonalisation: OnLoadPersonalisationInfo, questionNumber: number) => {
    const answers = parseQuestionAnswers(onLoadPersonalisation);
    if (!answers || answers.length === 0) {
        return null;
    }

    const questionAnswer = answers.find((a) => a.i === questionNumber);
    if (!questionAnswer) {
        return null;
    }
    return questionAnswer.answer;
};

export const getAnswerFromLocalStorage = (questionId: string, localStorageData?: LocalStorageQuestionAnswer[]) => {
    const questionAnswer = localStorageData.find((a) => a.id === questionId);
    return questionAnswer?.answer;
};

export const isDefaultLocalStorage = (onLoadPersonalisation: OnLoadPersonalisationInfo) =>
    onLoadPersonalisation.isSet && onLoadPersonalisation.type === OnLoadPersonalisationType.DefaultLocalStorage;

export const isWhenPersonalisationIsByLocalStorage = (
    onLoadPersonalisation: OnLoadPersonalisationInfo,
    personalisationState: PersonalisationStateInterface,
) =>
    onLoadPersonalisation.type === OnLoadPersonalisationType.LocalStorage &&
    personalisationState.industryCode === onLoadPersonalisation.industry &&
    personalisationState.sectorCode === onLoadPersonalisation.sector;

export const isLocalStorageContainingQuizResponsesWhenPersonalisationIsByLocalStorage = (
    onLoadPersonalisation: OnLoadPersonalisationInfo,
    personalisationState: PersonalisationStateInterface,
) =>
    !!(
        isWhenPersonalisationIsByLocalStorage(onLoadPersonalisation, personalisationState) &&
        localStorage.getItem('quiz - ' + personalisationState.sectorCode)
    );

export const isLocalStorageContainingQuizResponsesWhenPersonalisationIsByQuerystring = (
    onLoadPersonalisation: OnLoadPersonalisationInfo,
    personalisationState: PersonalisationStateInterface,
) =>
    !!(
        onLoadPersonalisation.type === OnLoadPersonalisationType.Querystring &&
        personalisationState.industryCode === onLoadPersonalisation.industry &&
        personalisationState.sectorCode === onLoadPersonalisation.sector &&
        localStorage.getItem('quiz - ' + personalisationState.sectorCode)
    );

export const readQuestionAnswersFromLocalStorage = (industry: string) => {
    const localStorageItemAsString = localStorage.getItem(getLocalStorageKey(industry));

    let localStorageParsedData = JSON.parse(localStorageItemAsString) as LocalStorageQuestionAnswer[];
    return localStorageParsedData;
};

export const storeQuestionAnswersToLocalStorage = (data: { id: any; answer: any }[], industry: string) => {
    let questionAnswers: LocalStorageQuestionAnswer[] = [];

    if (data) {
        questionAnswers = data.map((d: any) => {
            return {
                id: d.id,
                answer: d.answer,
            } as LocalStorageQuestionAnswer;
        });
    }
    localStorage.setItem(getLocalStorageKey(industry), JSON.stringify(questionAnswers));
};

export const getLocalStorageKey = (industry: string) => 'quiz - ' + industry;
