import TagManager from 'react-gtm-module';
import { UserProfile } from '../models/UserProfile';
import { getUserProfile } from './authUtils';

export const initTagManager = () => {
    const userProfile = getUserProfile();

    if (userProfile) {
        TagManager.initialize({
            gtmId: 'GTM-5QD69QM',
            dataLayer: getUserProfileDlv(userProfile),
        });
    } else {
        TagManager.initialize({
            gtmId: 'GTM-5QD69QM',
        });
    }
};

export const getUserProfileDlv = (userProfile: UserProfile) => ({
    userId: userProfile?.Id,
    dlv_user_type: userProfile?.BusinessType,
    dlv_user_role: userProfile?.Role,
    dlv_abn_provided: !!userProfile?.Abn,
});

export const sendUserEvent = (eventName: string, userProfile: UserProfile) => {
    TagManager.dataLayer({
        dataLayer: {
            event: eventName,
            ...getUserProfileDlv(userProfile),
        },
    });
};

export const sendWebinarEvent = (eventName: string, userProfile: UserProfile, webinarName: string) => {
    TagManager.dataLayer({
        dataLayer: {
            event: eventName,
            ...getUserProfileDlv(userProfile),
            dlv_webinar_name: webinarName,
        },
    });
};
