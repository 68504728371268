import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Helmet from 'react-helmet';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useAuth0 } from '@auth0/auth0-react';
import { IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import Pluralize from 'pluralize';
import LayoutResolver from '../LayoutResolver';
import PageBody from '../pageBody/PageBody';
import Breadcrumb from '../../site/breadcrumb/Breadcrumb';
import { client } from '../../config';
import Layout from '../../site/Layout';
import { PageItem } from '../../models/PageItem';
import NotFound from '../errorPages/NotFound';
import { PersonalisationStore } from '../../providers/personalisation';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { setPageInfo } from '../../providers/reducers/pageSlice';
import { getCurrentMarketNameInfo } from '../../providers/reducers/marketSlice';
import { getCurrentEventPageUrlByAceEventId } from '../../providers/reducers/eventSlice';
import { CurrentEventPageUrlInfo, CurrentMarketNameInfo, UserGeolocationInfo } from '../../models/ReduxModels';
import useSector from '../../hooks/useSector';
import { PageName } from '../../models/PageName';
import { useGetMvImportDocumentDataQuery } from '../../providers/reducers/mvLawsAndRegsApi';
import ErrorBoundary from '../../controls/error/ErrorBoundary';
import LawAndRegsPageOrchestrator from './LawsAndRegsPageOrchestrator';
import PageHeaderResolver from '../pageHeader/PageHeaderResolver';
import { initTagManager } from '../../utils/tagManagerUtils';

const Page = () => {
    const dispatch = useAppDispatch();
    const [contentRetrieved, setContentRetrieved] = useState<boolean>(false);
    const [content, setContent] = useState<PageItem>();
    const [linkedItems, setlinkedItems] = useState<IContentItemsContainer>();
    const [loading, setLoading] = useState<boolean>(true);
    const { pending: userGeolocationInfoPending } = useAppSelector<UserGeolocationInfo>((state) => state.user.geolocationInfo);
    const [documentName, setDocumentName] = useState(null);
    const { isLoading: authenticationLoading, isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const { data: eventPageUrl } = useAppSelector<CurrentEventPageUrlInfo>((state) => state.event.currentEventPageUrlInfo);
    const { sector } = useSector();
    const [sectorBreadCrumbLabel, setSectorBreadCrumbLabel] = useState('');
    const currentMarketNameInfo = useAppSelector<CurrentMarketNameInfo>((state) => state.market.currentMarketNameInfo);

    const url = window.location.href;
    const { pathname } = useLocation();
    let params, lastSlug;
    const { third_slug, industry, market, document: documentId } = (params = useParams());
    const [query] = useSearchParams();
    const navigate = useNavigate();

    const { data: documentData, isSuccess: documentDataRetrieved } = useGetMvImportDocumentDataQuery(
        { sector, market, documentId },
        { skip: !sector || !market || !documentId },
    );

    if (pathname.includes(PageName.ShortlistProducts)) {
        lastSlug = PageName.ShortlistProducts;
    } else if (pathname.includes(PageName.Shortlist)) {
        lastSlug = PageName.Shortlist;
    } else if (params && industry) {
        lastSlug = third_slug;
    } else if (pathname.includes(PageName.RulesAndRestrictionsDocument)) {
        lastSlug = PageName.RulesAndRestrictionsDocument;
    } else if (pathname.includes(PageName.RulesAndRestrictions)) {
        lastSlug = PageName.RulesAndRestrictions;
    } else if (pathname.includes(PageName.ProductRequirements)) {
        lastSlug = PageName.ProductRequirements;
    } else if (params && !industry && sector) {
        lastSlug = third_slug;
    } else {
        let parts = url.split('/');
        lastSlug = parts.pop() || parts.pop();
        lastSlug = lastSlug.replace(/#.*/g, '').replace(/(\?.*)$/, '');
    }

    const isEventRoutingUrl = useMemo(() => {
        const eventId = query.get('eventid');
        return url.includes(PageName.GoGlobalWebinars) && !!eventId;
    }, [query]);

    useEffect(() => {
        if (documentDataRetrieved && documentData) setDocumentName(documentData.Title);
    }, [documentDataRetrieved, documentData]);

    useEffect(() => {
        setSectorBreadCrumbLabel(sector);
    }, [sector]);

    useEffect(() => {
        if (isEventRoutingUrl) return;

        if (params && market) dispatch(getCurrentMarketNameInfo({ reactRouterDomMatch: { params: params } }));

        client
            .items()
            .equalsFilter('elements.url', lastSlug)
            .notEqualsFilter('system.workflow_step', 'archived')
            .excludeElementsParameter(['video_id', 'video_thumbnail', 'video_transcript'])
            .depthParameter(5)
            .toPromise()
            .then((response) => {
                setContent(response.data.items[0] as PageItem);
                setlinkedItems(response.data.linkedItems);
                setContentRetrieved(true);
                dispatch(setPageInfo({ codename: response.data.items[0]?.system.codename }));
            });

        if (window.history?.pushState) window.addEventListener('popstate', () => setLoadPosition());
        window.onload = () => setLoadPosition();
    }, [lastSlug]);

    useEffect(() => {
        if (!authenticationLoading) {
            if (isEventRoutingUrl) {
                if (isAuthenticated) dispatch(getCurrentEventPageUrlByAceEventId(query.get('eventid')));
                else loginWithRedirect();
            } else if (contentRetrieved) {
                if (content) {
                    const requireAuth = !!content.elements.show_in_navigation?.value?.find((item) => item.codename.startsWith('account_nav'));
                    if (requireAuth && !isAuthenticated) navigate(`/${PageName.SignIn}`, { replace: true });
                }

                if (isAuthenticated) checkAccessToken();

                setLoading(false);
                initTagManager();
            }
        }
    }, [authenticationLoading, contentRetrieved]);

    useEffect(() => {
        eventPageUrl && navigate(`/${eventPageUrl}`, { replace: true });
    }, [eventPageUrl]);

    const setLoadPosition = () => {
        if (url.includes('#')) {
            const linkPageId = url.split('#')[1];
            const pageId = document.getElementById(linkPageId);

            if (pageId) {
                const rect = pageId.getBoundingClientRect();
                document.documentElement.scrollTo(0, rect.top - 10);
            }
        } else {
            window.scrollTo(0, 0);
        }
    };

    const checkAccessToken = async () => {
        const token = await getAccessTokenSilently(); // Calling this method refreshes the access token if necessary
        if (!token) navigate(`/${PageName.Logout}?returnTo=/${PageName.SignIn}`, { replace: true });
    };

    const buildTariffFinderBreadCrumb = (marketNameInfoRetrieved: boolean) => {
        if (marketNameInfoRetrieved && url.includes(`${PageName.FindTariffs}/${PageName.Tariffs}`)) {
            return url.replace(
                new RegExp('/tariffs.*', 'g'),
                `/Tariffs for exporting ${sectorBreadCrumbLabel} from Australia to ${currentMarketNameInfo.data}`,
            );
        }

        return url;
    };

    const buildExportRulesFinderBreadCrumb = (marketNameInfoRetrieved: boolean) => {
        const isProduct = url.includes('/product-');

        if (marketNameInfoRetrieved && url.includes(`${PageName.ExportRulesFinder}/${PageName.ProductRequirements}`)) {
            if (url.includes(PageName.RulesAndRestrictionsDocument)) {
                return url.replace(
                    new RegExp(`/${PageName.ProductRequirements}.*`, 'g'),
                    `/Requirements ${isProduct ? 'HS code' : ''} ${sectorBreadCrumbLabel} to ${currentMarketNameInfo.data}/${documentName}`,
                );
            }

            return url.replace(
                new RegExp(`/${PageName.ProductRequirements}.*`, 'g'),
                `/Requirements ${isProduct ? 'HS code' : ''} ${sectorBreadCrumbLabel} to ${currentMarketNameInfo.data}`,
            );
        } else if (marketNameInfoRetrieved && url.includes(`${PageName.ExportRulesFinder}/${PageName.ServiceRequirements}`)) {
            return url.replace(new RegExp(`/${PageName.ServiceRequirements}.*`, 'g'), `/Exporting to ${currentMarketNameInfo.data}`);
        }

        return url;
    };

    const buildBreadCrumb = useCallback(() => {
        //Breadcrumbs are based on URL location
        const marketNameInfoRetrieved = currentMarketNameInfo.retrieved;

        const breadcrumbUrl =
            marketNameInfoRetrieved && url.includes('results')
                ? url.replace(/\/market-explorer-results.*/g, `/Exporting to ${currentMarketNameInfo.data}`)
                : url.includes(`${PageName.FindTariffs}/${PageName.Tariffs}`)
                  ? buildTariffFinderBreadCrumb(marketNameInfoRetrieved)
                  : url.includes(PageName.ExportRulesFinder)
                    ? buildExportRulesFinderBreadCrumb(marketNameInfoRetrieved)
                    : url.includes(PageName.Shortlist)
                      ? marketNameInfoRetrieved
                          ? url
                                .replace(/\/shortlist-products.*/g, `/${PageName.MarketSearchTool}/Exporting to ${currentMarketNameInfo.data}`)
                                .replace(/\/shortlist.*/g, `/${PageName.MarketSearchTool}/Exporting to ${currentMarketNameInfo.data}`)
                          : url
                      : url;

        const breadcrumbUrlParts = breadcrumbUrl
            .replace(/(\?.*)$/, '')
            .split('/')
            .slice(3);

        const breadcrumbParts = [
            {
                title: 'Home',
                url: '/',
            },
        ];

        const lastArray = breadcrumbUrlParts[breadcrumbUrlParts.length - 1];
        for (let j = 0; j < breadcrumbUrlParts.length; j++) {
            const urlPart = breadcrumbUrlParts[j];
            const link = '/' + breadcrumbUrlParts.slice(0, j + 1).join('/');

            let pageName = urlPart.replace(/-/g, ' ');
            pageName = pageName.charAt(0).toUpperCase() + pageName.slice(1);

            if (lastArray.replace(/-/g, ' ').toLowerCase() === pageName.toLowerCase() && breadcrumbUrlParts.length > 1) {
                breadcrumbParts.push({
                    title: pageName.replace(/#.*/g, ''),
                    url: '',
                });
            } else if (marketNameInfoRetrieved && pageName === `Exporting ${sectorBreadCrumbLabel} to ${currentMarketNameInfo.data}`) {
                breadcrumbParts.push({
                    title: pageName,
                    url: link.replace(pageName, `products/${sector}/${market}`),
                });
            } else if (marketNameInfoRetrieved && pageName === `Requirements HS code ${sectorBreadCrumbLabel} to ${currentMarketNameInfo.data}`) {
                breadcrumbParts.push({
                    title: pageName,
                    url: link.replace(pageName, `${PageName.ProductRequirements}/${sector}/${market}`),
                });
            } else {
                breadcrumbParts.push({
                    title: pageName,
                    url: breadcrumbUrlParts.length === 1 ? '' : link,
                });
            }
        }

        return breadcrumbParts;
    }, [currentMarketNameInfo.retrieved, sectorBreadCrumbLabel, documentName, lastSlug]);

    const breadCrumbData = buildBreadCrumb();

    return (
        <PersonalisationStore>
            <LoadingOverlay active={loading || userGeolocationInfoPending} spinner text="Please wait" className="loader">
                {contentRetrieved && !content ? (
                    <NotFound />
                ) : (
                    <ErrorBoundary>
                        <Layout>
                            {content && breadCrumbData ? <Breadcrumb menus={breadCrumbData} /> : ''}
                            {content && <PageHeaderResolver content={content} lastSlug={lastSlug} params={params} />}
                            {content && (
                                <>
                                    <Helmet>
                                        <meta
                                            name="category"
                                            content={
                                                content.elements.category?.value?.[0]?.name ? Pluralize(content.elements.category.value[0].name) : ''
                                            }
                                            data-react-helmet="true"
                                        />
                                    </Helmet>
                                    {((url.includes(PageName.FindTariffs) && third_slug === PageName.Tariffs) ||
                                        (url.includes(PageName.LawsAndRegulations) && url.includes(PageName.ProductRequirements))) && (
                                        <LawAndRegsPageOrchestrator data={{ item: content }} match={{ params: params }} />
                                    )}
                                    <PageBody pageType={content.elements.page_type}>
                                        <LayoutResolver
                                            data={{
                                                item: content as PageItem,
                                                linkedItems: linkedItems,
                                            }}
                                            match={{ params: params }}
                                        />
                                    </PageBody>
                                </>
                            )}
                        </Layout>
                    </ErrorBoundary>
                )}
            </LoadingOverlay>
        </PersonalisationStore>
    );
};

export default Page;
