import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import VimeoPlayer from 'react-player/vimeo';
import YoutubePlayer from 'react-player/youtube';
import LoadingOverlay from 'react-loading-overlay-ts';
import { SyncLoader } from 'react-spinners';
import { useAuth0 } from '@auth0/auth0-react';
import { ContentItem } from '@exporter-services/common-ui';
import './Video.scss';
import ResolveLink from '../resolveLink/ResolveLink';
import { personalisationContext } from '../../providers/personalisation';
import { validatePersonalisation } from '../../utils/personalisationUtils';
import HeadingLevelResolver from '../headingLevelResolver/HeadingLevelResolver';
import { getUserProfile } from '../../utils/authUtils';
import { PageName } from '../../models/PageName';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { VideoAuth, VideoInfo } from '../../models/ReduxModels';
import { VideoSource } from '../../models/enums';
import { getVideoAuth, getVideoInfo } from '../../providers/reducers/videoSlice';
import { AbnForm } from '../abn/AbnForm';
import Authentication from '../authentication/Authentication';
import { isHtmlEmpty } from '../../utils/utils';

interface VideoProps {
    data: {
        item: ContentItem;
    };
}

const Video = (props: VideoProps) => {
    const { personalisationState } = useContext(personalisationContext);
    const { isAuthenticated } = useAuth0();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { retrieved: videoInfoRetrieved, data: { videoId, thumbnailUrl, videoTranscript } = {} } = useAppSelector<VideoInfo>(
        (state) => state.video.videoInfo,
    );
    const { retrieved: videoAuthRetrieved, data: videoAuthData } = useAppSelector<VideoAuth>((state) => state.video.videoAuth);

    const requireAuth = props.data.item.elements.visibility?.value?.find((v) => v.codename === 'authenticated_users_only');
    const videoSource = props.data.item.elements.video_source?.value?.[0].codename;
    const key = videoSource === VideoSource.Vimeo ? 'playerOptions' : 'playerVars';
    const urlMap = {
        youtube: 'www.youtube.com/watch?v=',
        vimeo: 'vimeo.com/',
    };

    const [loading, setLoading] = useState(false);
    const [showAbnPrompt, setShowAbnPrompt] = useState(false);
    const [showVideo, setShowVideo] = useState(false);
    const [playing, setPlaying] = useState<boolean>(false);
    const [showAuthPrompt, setShowAuthPrompt] = useState(false);

    useEffect(() => {
        if (validatePersonalisation(personalisationState, props.data.item.elements.industry_sector)) {
            if (!requireAuth) setShowVideo(true);
            else if (isAuthenticated) {
                const userProfile = getUserProfile();
                if (!userProfile) navigate(`/${PageName.SignIn}/${PageName.CompleteYourProfile}`, { state: { from: location.pathname } });
                else if (userProfile?.IsBusinessUser && !userProfile?.Abn) setShowAbnPrompt(true);
                else setShowVideo(true);
            } else setShowAuthPrompt(true);
        }
    }, []);

    useEffect(() => {
        if (showVideo) {
            setLoading(true);
            dispatch(getVideoInfo({ codename: props.data.item.system.codename }));
        }
    }, [showVideo]);

    useEffect(() => {
        if (showAuthPrompt) {
            setLoading(true);
            dispatch(getVideoAuth());
        }
    }, [showAuthPrompt]);

    useEffect(() => {
        if (videoInfoRetrieved || videoAuthRetrieved) setLoading(false);
    }, [videoInfoRetrieved, videoAuthRetrieved]);

    const onPlay = () => setPlaying(true);

    const onEnded = () => setPlaying(false);

    const clickHandler = () => setPlaying(true);

    const playerProps = useMemo(
        () => ({
            onPlay: onPlay,
            onEnded: onEnded,
            width: '100%',
            height: '100%',
            playing,
            onSeek: () => null,
            config: {
                [key]: {
                    color: 'white',
                    showinfo: 0,
                    modestbranding: 1,
                    controls: 1,
                    enablejsapi: 1,
                    rel: 0,
                },
            },
            className: 'video-player-control',
            url: `https://${urlMap[videoSource]}${videoId}`,
        }),
        [videoSource, videoId, playing],
    );

    const showVideoTranscript = !isHtmlEmpty(videoTranscript);

    return (
        <LoadingOverlay active={loading} spinner={<SyncLoader />} text="Please wait..." className="loader">
            {showAbnPrompt && (
                <div className="abn-prompt">
                    <AbnForm
                        onSuccess={() => {
                            setShowAbnPrompt(false);
                            setShowVideo(true);
                        }}
                        title="Provide your ABN to view the webinar"
                    />
                </div>
            )}
            {showVideo && videoId && (
                <div className={`video-player${showVideoTranscript ? ' with-transcript' : ''}`} data-testid="video-player">
                    <div className="video-player-header">
                        <HeadingLevelResolver
                            data={{
                                headingLevel: props.data.item.elements.headinglevelsnippet__level,
                                titleText: props.data.item.elements.title.value,
                            }}
                        />
                        <ResolveLink data={{ item: props.data.item }} type={props.data.item.system.type} />
                    </div>
                    <div
                        className="video-player-player"
                        onClick={clickHandler}
                        style={{
                            backgroundImage: `url(${videoSource === VideoSource.Youtube && !thumbnailUrl ? `https://img.youtube.com/vi/${videoId}/0.jpg` : thumbnailUrl})`,
                        }}
                    >
                        <button
                            aria-label={`Play video ${props.data.item.elements.title.value}`}
                            className={`video-player-button ${!playing ? 'visible' : 'invisible'}`}
                            onClick={clickHandler}
                        ></button>
                        {videoSource === VideoSource.Vimeo ? (
                            <VimeoPlayer controls style={{ visibility: playing ? 'visible' : 'hidden' }} {...playerProps} />
                        ) : (
                            <YoutubePlayer controls style={{ visibility: playing ? 'visible' : 'hidden' }} {...playerProps} />
                        )}
                    </div>

                    {showVideoTranscript && (
                        <div className="video-player-transcript">
                            <details>
                                <summary>
                                    <div className="transcript-toggle"></div>
                                    <div className="arrow-down-icon"></div>
                                </summary>
                                <div dangerouslySetInnerHTML={{ __html: videoTranscript }}></div>
                            </details>
                        </div>
                    )}
                </div>
            )}
            {showAuthPrompt && videoAuthData?.elements && <Authentication data={{ item: videoAuthData as ContentItem }} />}
        </LoadingOverlay>
    );
};

export default Video;
