import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SyncLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay-ts';
import { ComButton } from '@exporter-services/common-ui';
import { useAuth0 } from '@auth0/auth0-react';
import './EventDetails.scss';
import { AbnModal } from '../abn/AbnModal';
import AuthenticationModal from '../authentication/AuthenticationModal';
import { PageName } from '../../models/PageName';
import { WebinarEvent } from '../../models/WebinarEvent';
import { formatEventStartDateEndDate } from '../../utils/dateUtils';
import { getUserProfile } from '../../utils/authUtils';
import { sendWebinarEvent } from '../../utils/tagManagerUtils';
import {
    useGetEventDetailsQuery,
    useGetEventDetailsUnauthenticatedQuery,
    useRegisterForEventMutation,
    useCancelEventRegistrationMutation,
    useCheckinForEventMutation,
} from '../../providers/reducers/eventsApi';
import { EventStatus, EventDetailsProps, EventStateMap } from './EventDetailsHelper';
import { useAppSelector } from '../../hooks/reduxHooks';
import ConfirmCancellationModal from './ConfirmCancellationModal';

const EventDetails = (props: EventDetailsProps) => {
    const SPOTS_LEFT_THRESHOLD = 15;
    const TIMER_TRIGGER_TRHESHOLD_MINUTES = 4320;
    const TRIGGER_INTERVAL_SECONDS = 60;
    const navigate = useNavigate();
    const location = useLocation();
    const { isAuthenticated, isLoading: authApiLoading } = useAuth0();
    const [operationInProgress, setOperationInProgress] = useState(false);
    const [showAuthenticationModal, setShowAuthenticationModal] = useState(false);
    const [showAbnModal, setShowAbnModal] = useState(false);
    const [showConfirmCancellationModal, setShowConfirmCancellationModal] = useState(false);
    const [eventDetailsFromAce, setEventDetailsFromAce] = useState<WebinarEvent>(null);
    const [minutesUntilStart, setMinutesUntilStart] = useState(eventDetailsFromAce?.MinutesUntilStart);
    const [minutesSinceEnd, setMinutesSinceEnd] = useState(eventDetailsFromAce?.MinutesSinceEnd);

    const aceEventId = useAppSelector<string>((state) => state.event.currentAceEventId);

    const [createEventRegistration] = useRegisterForEventMutation();
    const [cancelEventRegistration] = useCancelEventRegistrationMutation();
    const [checkinToEvent] = useCheckinForEventMutation();
    const {
        data: eventDetails,
        isLoading: eventApiLoading,
        isFetching: eventApiFetching,
    } = useGetEventDetailsQuery({ eventId: aceEventId }, { skip: !isAuthenticated || !aceEventId });
    const {
        data: eventDetailsUnauthenticated,
        isLoading: eventApiUnauthenticatedLoading,
        isFetching: eventApiUnauthenticatedFetching,
    } = useGetEventDetailsUnauthenticatedQuery({ eventId: aceEventId }, { skip: isAuthenticated || !aceEventId });

    const isCurrentUserRegistered = eventDetailsFromAce?.IsCurrentUserRegistered;
    const spotsLeft =
        eventDetailsFromAce?.MaxRegistrations !== null ? eventDetailsFromAce?.MaxRegistrations - eventDetailsFromAce?.CurrentRegistrations : null;

    const thresholdMinutesUntilStart = eventDetailsFromAce?.ThresholdMinutesUntilStart;
    const thresholdMinutesSinceEnd = eventDetailsFromAce?.ThresholdMinutesSinceEnd;

    const loading =
        authApiLoading ||
        eventApiLoading ||
        eventApiFetching ||
        eventApiUnauthenticatedLoading ||
        eventApiUnauthenticatedFetching ||
        operationInProgress;

    const eventState: EventStatus = useMemo(() => {
        if (minutesSinceEnd >= thresholdMinutesSinceEnd) return EventStatus.Closed;
        else if (isCurrentUserRegistered && minutesUntilStart <= thresholdMinutesUntilStart) return EventStatus.JoinNow;
        else if (isCurrentUserRegistered) return EventStatus.BookingConfirmed;
        else if (spotsLeft === 0 || minutesUntilStart <= thresholdMinutesUntilStart) return EventStatus.BookingsClosed;
        return EventStatus.BookingsOpen;
    }, [isCurrentUserRegistered, spotsLeft, minutesUntilStart, minutesSinceEnd]);

    const description =
        EventStateMap[eventState].noSpotsLeftDescription && spotsLeft === 0
            ? EventStateMap[eventState].noSpotsLeftDescription
            : EventStateMap[eventState].description;

    let intervalStart: NodeJS.Timeout | null = null;
    let intervalEnd: NodeJS.Timeout | null = null;

    useEffect(() => {
        if (minutesUntilStart < TIMER_TRIGGER_TRHESHOLD_MINUTES && minutesUntilStart > thresholdMinutesUntilStart)
            intervalStart = setInterval(() => {
                setMinutesUntilStart((prevTime) => prevTime - TRIGGER_INTERVAL_SECONDS / 60);
            }, TRIGGER_INTERVAL_SECONDS * 1000);
        else clearInterval(intervalStart!);

        return () => clearInterval(intervalStart!);
    }, [minutesUntilStart]);

    useEffect(() => {
        if (minutesSinceEnd < thresholdMinutesSinceEnd && minutesSinceEnd > -TIMER_TRIGGER_TRHESHOLD_MINUTES)
            intervalEnd = setInterval(() => {
                setMinutesSinceEnd((prevTime) => prevTime + TRIGGER_INTERVAL_SECONDS / 60);
            }, TRIGGER_INTERVAL_SECONDS * 1000);
        else clearInterval(intervalEnd!);

        return () => clearInterval(intervalEnd!);
    }, [minutesSinceEnd]);

    useEffect(() => setMinutesUntilStart(eventDetailsFromAce?.MinutesUntilStart), [eventDetailsFromAce?.MinutesUntilStart]);
    useEffect(() => setMinutesSinceEnd(eventDetailsFromAce?.MinutesSinceEnd), [eventDetailsFromAce?.MinutesSinceEnd]);

    useEffect(
        () => setEventDetailsFromAce(isAuthenticated ? eventDetails : eventDetailsUnauthenticated),
        [isAuthenticated, eventDetails, eventDetailsUnauthenticated],
    );

    const getEventDateDetails = () =>
        formatEventStartDateEndDate(props.data.item.elements?.start_date?.value, props.data.item.elements?.end_date?.value);

    const registerForEvent = async () => {
        setShowAbnModal(false);
        setOperationInProgress(true);

        const { error, data } = await createEventRegistration(aceEventId);
        if (!error) {
            const userProfile = getUserProfile();
            sendWebinarEvent('webinar_booked', userProfile, eventDetailsFromAce.Name);

            setEventDetailsFromAce(data);
        }

        setOperationInProgress(false);
    };

    const unregisterEvent = async () => {
        setShowConfirmCancellationModal(false);
        setOperationInProgress(true);

        const { error, data } = await cancelEventRegistration(aceEventId);
        if (!error) {
            const userProfile = getUserProfile();
            sendWebinarEvent('webinar_booking_cancelled', userProfile, eventDetailsFromAce.Name);

            setEventDetailsFromAce(data);
        }

        setOperationInProgress(false);
    };

    const handleRegistration = async () => {
        if (isAuthenticated) {
            const userProfile = getUserProfile();
            if (!userProfile) navigate(`/${PageName.SignIn}/${PageName.CompleteYourProfile}`, { state: { from: location.pathname } });
            else if (userProfile?.IsBusinessUser && !userProfile?.Abn) setShowAbnModal(true);
            else await registerForEvent();
        } else {
            setShowAuthenticationModal(true);
        }
    };

    const handleCancellation = () => setShowConfirmCancellationModal(true);

    const handleCheckin = async () => {
        const link = document.createElement('a');
        link.href = eventDetailsFromAce?.TeamsUrl;
        link.target = '_blank';
        link.click();

        if (!eventDetailsFromAce.HasCurrentUserAttended) {
            const { error, data: checkinEventData } = await checkinToEvent(aceEventId);

            if (!error) {
                setEventDetailsFromAce(checkinEventData);

                const userProfile = getUserProfile();
                sendWebinarEvent('webinar_attended', userProfile, eventDetailsFromAce.Name);
            }
        }
    };

    const eventHandlerMap = {
        [EventStatus.BookingsOpen]: handleRegistration,
        [EventStatus.BookingConfirmed]: handleCancellation,
        [EventStatus.JoinNow]: handleCheckin,
    };

    return !aceEventId ? null : (
        <LoadingOverlay active={loading} spinner={<SyncLoader />} text="Please wait..." className="loader">
            <div className="event-details pb-5">
                <div className={`event-details-content-wrapper ${EventStateMap[eventState].wrapperClass}`}>
                    <div className="event-details-content p-4 h-100">
                        <div className="center-icon mb-3">
                            <div className="icon-wrapper">
                                <span className={EventStateMap[eventState].iconClass}></span>
                            </div>
                        </div>
                        <h2>{EventStateMap[eventState].title}</h2>
                        {description && <p>{description}</p>}
                        <div className="item">
                            <div className="label">Date:</div>
                            <div dangerouslySetInnerHTML={{ __html: getEventDateDetails() }}></div>
                        </div>
                        <div className="item">
                            <div className="label">Time:</div>
                            <div>{props.data.item.elements?.time?.value}</div>
                        </div>
                        <div className="item">
                            <div className="label">Location:</div>
                            <div>{props.data.item.elements?.location?.value}</div>
                        </div>
                        <div className="item">
                            <div className="label">Cost:</div>
                            <div>{props.data.item.elements?.cost?.value}</div>
                        </div>
                    </div>
                    <div className="event-details-footer d-flex align-items-center p-4">
                        {EventStateMap[eventState].button && (
                            <ComButton variant={EventStateMap[eventState].button.variant} onClick={eventHandlerMap[eventState]}>
                                {isAuthenticated ? EventStateMap[eventState].button.text : EventStateMap[eventState].button.signinText}
                            </ComButton>
                        )}
                        {eventState === EventStatus.BookingsOpen && spotsLeft > 0 && spotsLeft <= SPOTS_LEFT_THRESHOLD && (
                            <div className="spots-left px-5">
                                <strong>{spotsLeft}</strong> spot{spotsLeft > 1 ? 's' : ''} left
                            </div>
                        )}
                    </div>
                </div>
                {showAuthenticationModal && <AuthenticationModal onClose={() => setShowAuthenticationModal(false)} />}
                {showAbnModal && <AbnModal onClose={() => setShowAbnModal(false)} onSuccess={registerForEvent} />}
                {showConfirmCancellationModal && (
                    <ConfirmCancellationModal onClose={() => setShowConfirmCancellationModal(false)} onSuccess={unregisterEvent} />
                )}
            </div>
        </LoadingOverlay>
    );
};

export default EventDetails;
