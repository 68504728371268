import { VideoAuth, VideoInfo } from '../../models/ReduxModels';
import GlobalState, { VideoState } from '../globalState/globalState';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initState: VideoState = {
    videoInfo: { retrieved: false, pending: false, data: undefined },
    videoAuth: { retrieved: false, pending: false, data: undefined },
};

const videoSlice = createSlice({
    name: 'video',
    initialState: initState,
    reducers: {
        pendingVideoInfo(state) {
            state.videoInfo.pending = true;
        },
        pendingVideoAuth(state) {
            state.videoAuth.pending = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getVideoInfo.fulfilled, (state, action) => {
            state.videoInfo = action.payload;
        });
        builder.addCase(getVideoAuth.fulfilled, (state, action) => {
            state.videoAuth = action.payload;
        });
    },
});

export const getVideoInfo = createAsyncThunk('video/getVideoInfo', async (request: { codename: string }, thunkAPI) => {
    thunkAPI.dispatch(videoSlice.actions.pendingVideoInfo());

    return await new Promise<VideoInfo>((resolve) => {
        const state = thunkAPI.getState() as GlobalState;
        state.singletonServices.videoService
            .getVideoData(request.codename)
            .then(({ videoId, thumbnailUrl, videoTranscript }) => {
                resolve({ retrieved: true, pending: false, data: { thumbnailUrl, videoId, videoTranscript } });
            })
            .catch(() => {
                resolve({ retrieved: true, pending: false });
            });
    });
});

export const getVideoAuth = createAsyncThunk('video/getVideoAuth', async (_, thunkAPI) => {
    thunkAPI.dispatch(videoSlice.actions.pendingVideoAuth());

    return await new Promise<VideoAuth>((resolve) => {
        const state = thunkAPI.getState() as GlobalState;
        state.singletonServices.videoService
            .getVideoAuth()
            .then((response) => {
                resolve({ retrieved: true, pending: false, data: response });
            })
            .catch(() => {
                resolve({ retrieved: true, pending: false });
            });
    });
});

const videoReducer = videoSlice.reducer;

export default videoReducer;
